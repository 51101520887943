.matrix-label {
  text-align: left;
  color: #052675;

}

.card-body-content {
  background: #f6fbff;
  border-radius: 10px;
  text-align: left;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
}

.label-leters {
  padding-top: 10px;
  text-align: left;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: #000000;
}

.size {
  width: 230px;
}

.trash-align {
  position: absolute;
  top: 6px;
  right: 6px;
  text-align: center;
  line-height: 13px;
  font-weight: bold;
  padding-top: 7px;
  color: brown;

}

.plus-btn {

  color: #052675;
}

input[type=date] {
  width: 100%;
}


.main-insured-title-box {
  text-align: center;
  background-color: rgba(5.000000176951289, 38.0000015348196, 117.00000062584877, 1);
  width: 319px;
  height: 50px;
  margin-bottom: 5px;
  -webkit-border-radius: 0px 0px 40px 40px;
  -moz-border-radius: 0px 0px 40px 40px;
  border-radius: 0px 0px 40px 40px;

}

.additional-insured-title-box {
  text-align: center;
  background: #D3E9FF;
  width: 319px;
  height: 50px;
  margin-bottom: 5px;
  -webkit-border-radius: 0px 0px 40px 40px;
  -moz-border-radius: 0px 0px 40px 40px;
  border-radius: 0px 0px 40px 40px;

}

.image-svg-insurance {
  fill: #052675;
  float: right;
  margin-top: 12px;
  margin-right: 8px;
}

.image-svg-insurance-plus {
  fill: #052675;
  float: right;
  margin-top: 0px;
  margin-right: 2px;
}


.matrix-button {
  width: 296px;
  max-width: 100%;
  padding: 15px;
}

.div-matrix-button {
  display: grid;
  place-items: center;
}
.title-card-insurance{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  text-align: left;
  margin-left: 20px;
  margin-top: 10px;
  color: #052675;
}

/* when screen resolution is under 600px the date input will take 100% of the div*/
@media screen and (max-width: 600px) {
  .btn-image-insurance {
    width: 230px !important;
    ;
  }

  @media screen and (max-width: 420px) {
    .btn-image-insurance {
      width: 190px !important;
    }

    .matrix-button {
      width: 100%;
    }

    .main-insured-title-box,
    .additional-insured-title-box {
      width: 100%;
    }
  }
}