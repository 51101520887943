.separator-list {
  background-color: #052675;
  margin-left: 5px !important;

}

.container-check {
  width: 100%;
  padding: 10px;
}

.container-field {
  width: 100%;
  padding: 0px;
}


.card-rider {
  width: 100%;
  background: #d3e9ff9c;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
}
.card-rider-health {
  width: 100%;
  background: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #d3e9ff9c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
}
.rider-field-health{
  padding: 9px 0px 0px 10px;
}


.rider-list {
  background: #EDF6FF;
  border: 1px solid #D3E9FF;
  box-sizing: border-box;
  border-radius: 0.625rem;
  content: normal;
  width: 28.25rem;
  height: 2.813rem;
}


.rider-container-inputs {
  display: flex;
  flex-wrap: wrap;
  width: 98%;
}

.rider-container-options {
  width: 28.25rem;
  height: 1.875rem;
  margin-top: 1rem;
  background: #FFFFFF;
}

.multiple-choise {
  text-align: left;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #D3E9FF;
  box-sizing: border-box;
  margin-top: 10px;
  border-radius: 10px !important;
}

.categories {
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: #4E4E4E;
  height: 27px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
}

.categories-label {
  position: absolute;
  top: 8px;
  left: 50px;
}

.rider-check {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  min-height: 43px;
  background: #FFFFFF;
  border: 1px solid #D3E9FF;
  box-sizing: border-box;
  border-radius: 5px;
}

input[type="checkbox"].input-check-rider {
  width: 13px;
  height: 13px;
}

input[type="checkbox"].input-check-rider:after {
  height: 5px;
  width: 5px;
  left: 4px;
  top: 4px;
}
.modal-icon-rider {
  right: 15px;
  position: absolute;
  top: 18%;
  
}
.modal-icon-rider-save {
  right: 30px;
  position: absolute;
  top: 50%;
  
}