.inpt-select {
   /* usually 30% of the div*/
   width: 35% !important;
}

/* when screen resolution is under 600px the date input will take 100% of the div*/
@media screen and (max-width: 600px) {
   .inpt-select {
      width: 100% !important;
      ;
   }
}

.custom-select {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxjaXJjbGUgY3g9IjEyLjUiIGN5PSIxMi41IiByPSIxMi41IiB0cmFuc2Zvcm09InJvdGF0ZSgtOTAgMTIuNSAxMi41KSIgZmlsbD0iIzA1MjY3NSIvPg0KPHBhdGggZD0iTTYuMTk4MDcgMTAuMjQzN0wxMi4zNzY5IDE1LjgxMjdMMTguNTU1NyAxMC4yNDM3IiBzdHJva2U9IiNEM0U5RkYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg==");
      background-repeat: no-repeat;
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      appearance:none;
      background-position-x: 95%;
      background-position-y: 15px;  
      background-size: 37px 38px;
    overflow: hidden;
}
.custom-select-matrix {
   background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxjaXJjbGUgY3g9IjEyLjUiIGN5PSIxMi41IiByPSIxMi41IiB0cmFuc2Zvcm09InJvdGF0ZSgtOTAgMTIuNSAxMi41KSIgZmlsbD0iIzA1MjY3NSIvPg0KPHBhdGggZD0iTTYuMTk4MDcgMTAuMjQzN0wxMi4zNzY5IDE1LjgxMjdMMTguNTU1NyAxMC4yNDM3IiBzdHJva2U9IiNEM0U5RkYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg==");
   background-repeat: no-repeat;
   -moz-appearance:none; /* Firefox */
   -webkit-appearance:none; /* Safari and Chrome */
   appearance:none;
   background-position-x: 95%;
   background-position-y: 15px;  
   background-size: 25px 25px;
}
.custom-alert {
   background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzNyAzOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxlbGxpcHNlIGN4PSIxOC41IiBjeT0iMTkuMzk2NSIgcng9IjE4LjUiIHJ5PSIxOC40NDI4IiBmaWxsPSIjRjIyNzRDIi8+DQo8cGF0aCBkPSJNMTcgMjcuODgxNEMxNyAyNy40MjU5IDE3LjE2NzUgMjcuMDI5OSAxNy41MDI2IDI2LjY5MzJDMTcuODM3NyAyNi4zNTY2IDE4LjIzMTkgMjYuMTg4MyAxOC42ODUyIDI2LjE4ODNDMTkuMTM4NSAyNi4xODgzIDE5LjUzMjcgMjYuMzU2NiAxOS44Njc4IDI2LjY5MzJDMjAuMjAyOSAyNy4wMjk5IDIwLjM3MDQgMjcuNDI1OSAyMC4zNzA0IDI3Ljg4MTRDMjAuMzcwNCAyOC4zMzY4IDIwLjIwMjkgMjguNzIyOSAxOS44Njc4IDI5LjAzOThDMTkuNTMyNyAyOS4zMzY4IDE5LjEzODUgMjkuNDg1MyAxOC42ODUyIDI5LjQ4NTNDMTguMjMxOSAyOS40ODUzIDE3LjgzNzcgMjkuMzM2OCAxNy41MDI2IDI5LjAzOThDMTcuMTY3NSAyOC43MjI5IDE3IDI4LjMzNjggMTcgMjcuODgxNFpNMTguMzg5NiAyNC4xMzg4QzE4LjMxMDcgMjMuMjA4MSAxOC4yMjIgMjIuMjI3OSAxOC4xMjM1IDIxLjE5ODJDMTguMDQ0NiAyMC4xNDg3IDE3Ljk1NTkgMTkuMTE5IDE3Ljg1NzQgMTguMTA5MUMxNy43Nzg1IDE3LjA3OTQgMTcuNjk5NyAxNi4wOTkyIDE3LjYyMDkgMTUuMTY4NUMxNy41NjE3IDE0LjIzNzggMTcuNTAyNiAxMy40MDYxIDE3LjQ0MzUgMTIuNjczNEMxNy4zODQzIDExLjkyMSAxNy4zMzUxIDExLjMwNzEgMTcuMjk1NyAxMC44MzE5QzE3LjI1NjIgMTAuMzM2OCAxNy4yMzY1IDEwLjAzOTggMTcuMjM2NSA5Ljk0MDc3QzE3LjIzNjUgOS40MDYxMSAxNy4zODQzIDkuMDI5ODcgMTcuNjggOC44MTIwNUMxNy45NzU2IDguNTk0MjMgMTguMzEwNyA4LjQ4NTMyIDE4LjY4NTIgOC40ODUzMkMxOS4wNTk3IDguNDg1MzIgMTkuMzk0OCA4LjU5NDIzIDE5LjY5MDQgOC44MTIwNUMxOS45ODYxIDkuMDI5ODcgMjAuMTMzOSA5LjQwNjExIDIwLjEzMzkgOS45NDA3N0MyMC4xMzM5IDEwLjA5OTIgMjAuMDk0NSAxMC42NjM1IDIwLjAxNTYgMTEuNjMzOEMxOS45MzY4IDEyLjYwNDEgMTkuODM4MiAxMy43ODI0IDE5LjcyIDE1LjE2ODVDMTkuNjIxNCAxNi41NTQ2IDE5LjUwMzIgMTguMDQ5NyAxOS4zNjUyIDE5LjY1MzZDMTkuMjI3MiAyMS4yMzc4IDE5LjA5OTEgMjIuNzMyOCAxOC45ODA5IDI0LjEzODhIMTguMzg5NloiIGZpbGw9IndoaXRlIi8+DQo8L3N2Zz4NCg==");
   background-repeat: no-repeat;
   -moz-appearance:none; /* Firefox */
   -webkit-appearance:none; /* Safari and Chrome */
   appearance:none;
   background-position-x: 95%;
   background-position-y: 15px;  
   background-size: 37px 38px; 
}
.custom-alert-matrix {
   background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzNyAzOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxlbGxpcHNlIGN4PSIxOC41IiBjeT0iMTkuMzk2NSIgcng9IjE4LjUiIHJ5PSIxOC40NDI4IiBmaWxsPSIjRjIyNzRDIi8+DQo8cGF0aCBkPSJNMTcgMjcuODgxNEMxNyAyNy40MjU5IDE3LjE2NzUgMjcuMDI5OSAxNy41MDI2IDI2LjY5MzJDMTcuODM3NyAyNi4zNTY2IDE4LjIzMTkgMjYuMTg4MyAxOC42ODUyIDI2LjE4ODNDMTkuMTM4NSAyNi4xODgzIDE5LjUzMjcgMjYuMzU2NiAxOS44Njc4IDI2LjY5MzJDMjAuMjAyOSAyNy4wMjk5IDIwLjM3MDQgMjcuNDI1OSAyMC4zNzA0IDI3Ljg4MTRDMjAuMzcwNCAyOC4zMzY4IDIwLjIwMjkgMjguNzIyOSAxOS44Njc4IDI5LjAzOThDMTkuNTMyNyAyOS4zMzY4IDE5LjEzODUgMjkuNDg1MyAxOC42ODUyIDI5LjQ4NTNDMTguMjMxOSAyOS40ODUzIDE3LjgzNzcgMjkuMzM2OCAxNy41MDI2IDI5LjAzOThDMTcuMTY3NSAyOC43MjI5IDE3IDI4LjMzNjggMTcgMjcuODgxNFpNMTguMzg5NiAyNC4xMzg4QzE4LjMxMDcgMjMuMjA4MSAxOC4yMjIgMjIuMjI3OSAxOC4xMjM1IDIxLjE5ODJDMTguMDQ0NiAyMC4xNDg3IDE3Ljk1NTkgMTkuMTE5IDE3Ljg1NzQgMTguMTA5MUMxNy43Nzg1IDE3LjA3OTQgMTcuNjk5NyAxNi4wOTkyIDE3LjYyMDkgMTUuMTY4NUMxNy41NjE3IDE0LjIzNzggMTcuNTAyNiAxMy40MDYxIDE3LjQ0MzUgMTIuNjczNEMxNy4zODQzIDExLjkyMSAxNy4zMzUxIDExLjMwNzEgMTcuMjk1NyAxMC44MzE5QzE3LjI1NjIgMTAuMzM2OCAxNy4yMzY1IDEwLjAzOTggMTcuMjM2NSA5Ljk0MDc3QzE3LjIzNjUgOS40MDYxMSAxNy4zODQzIDkuMDI5ODcgMTcuNjggOC44MTIwNUMxNy45NzU2IDguNTk0MjMgMTguMzEwNyA4LjQ4NTMyIDE4LjY4NTIgOC40ODUzMkMxOS4wNTk3IDguNDg1MzIgMTkuMzk0OCA4LjU5NDIzIDE5LjY5MDQgOC44MTIwNUMxOS45ODYxIDkuMDI5ODcgMjAuMTMzOSA5LjQwNjExIDIwLjEzMzkgOS45NDA3N0MyMC4xMzM5IDEwLjA5OTIgMjAuMDk0NSAxMC42NjM1IDIwLjAxNTYgMTEuNjMzOEMxOS45MzY4IDEyLjYwNDEgMTkuODM4MiAxMy43ODI0IDE5LjcyIDE1LjE2ODVDMTkuNjIxNCAxNi41NTQ2IDE5LjUwMzIgMTguMDQ5NyAxOS4zNjUyIDE5LjY1MzZDMTkuMjI3MiAyMS4yMzc4IDE5LjA5OTEgMjIuNzMyOCAxOC45ODA5IDI0LjEzODhIMTguMzg5NloiIGZpbGw9IndoaXRlIi8+DQo8L3N2Zz4NCg==");
   background-repeat: no-repeat;
   -moz-appearance:none; /* Firefox */
   -webkit-appearance:none; /* Safari and Chrome */
   appearance:none;
   background-position-x: 95%;
   background-position-y: 15px;  
   background-size: 25px 25px; 
}