.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}

.width-container {
    width: 200px;
}

.home-button-container {
    margin-top: 10%;
    width: 30%;
}

.btn-text-home {
    border: 0px solid #D3E9FF;
    height: 53px;
    background: #D3E9FF;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.086);
    border-radius: 5px 0px 0px 5px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 110.2%;
    text-align: center;
    text-transform: uppercase;
    color: #052675;
}

.btn-image-home {
    width: 53px;
    height: 53px;
    position: absolute;
    background: #c9e2fa;
    border-radius: 0px 5px 5px 0px;
}

.bs-stepper .bs-stepper-content {
    padding: 0px;
}

.back-button {
    font-weight: 700;
    margin: 24px;
    background-color: #fff;
    color: #aaaaad;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.btn-tsv {
    width: 140px !important;
}


@media (min-width: 360rem) {
    .button-next {
        width: 100%;
    }
}

.svg-next {
    fill: #052675;
}

.progress-bar {
    background: #FFDB81;
    height: 5px;
}

.progress {
    background-color: #D3E9FF;
    height: 5px;
}

.riderList {
    margin-top: 30px;
}

.btn-home {
    width: 100%;
}

.btn-home-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.information-text{
    padding-top: 10px;
}
.information-text p {
    font-weight: normal;
    color: #052675;
}


@media (min-width:320px) {
    .btn-home {
        width: 80%;
    }
}

@media (min-width:600px) {

    .btn-home {
        width: 60%;
    }
}

@media (min-width: 900px) {

    .btn-home {
        width: 19%;
    }

}