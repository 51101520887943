input[type="text"].date-card {

   width: 30% !important;
}

.inpt-date {
   /* usually 30% of the div*/
   width: 30% !important;
   ;
}

input[type="date"]::-webkit-calendar-picker-indicator {
   background-image: url('data:image/svg+xml;utf8,');
}

/* when screen resolution is under 600px the date input will take 100% of the div*/
@media screen and (max-width: 600px) {
   .inpt-date {
      width: 100% !important;
      ;
   }
}

input[type="date"]+i {
   float: right;
   margin-right: 10px;
   padding-right: 5px;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
   background-image: none;
}
.date-i-matrix {
   margin-top: -45px;
}