.not-products-tittle {
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 43px;
    text-align: center;
    color: #052675;
    margin-bottom: 1rem;
}

.div-title-sales {
    margin-top: 10px;
    margin-left: 80px;
}

.main-box {
    margin: auto;
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: gainsboro;
    width: 650px;
}

.main-box-container {
    margin-left: 10px;
    margin-right: 10px;
}

.btn-frm-sales {
    background-color: #052675;
    color: #D3E9FF;
    text-transform: uppercase;
    width: 150px;
    height: 40px;
    border: none;
    border-radius: 4px;
    margin-right: 10px;
}

.error-message {
    color: red;
}

.title-wel-product {
    font-style: normal;
    font-size: 30px;
    line-height: 43px;
    text-align: center;
    color: #052675;
}

.title-wel-sub-product {
    font-size: 20px;
    text-align: center;
    line-height: 43px;
    color: #052675;
}

@media (max-width: 380px) {
    .title-wel-product {
        font-size: 20px;

    }
    .title-wel-sub-product {
        font-size: 15px;

    }
}

.main-products {
    max-width: 1200px;
    margin: 0 auto;
}

.products-cards {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}
.products-border-cards{
    padding: 10px;
    border: 1px dashed #D3E9FF;
    border-radius: 10px;
  }
