.product-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  min-height: 395px;
  background: #FFFFFF;
  border: 1px solid #D3E9FF;
  box-sizing: border-box;
  box-shadow: 4px 5px 4px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

.product-title {
  margin-top: 10px;
  display: inline-table;
}
.product-title-description {
  margin-top: 30px;
  display: inline-table;
  font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 24px;
color: #FFFFFF;

}

.products-cards-item {
  display: flex;
  padding: 1rem;
}


.card-title-products {
  text-align: center;
  margin-bottom: 0px;
  min-height: 100px;
  font-size: 25px;
  font-weight: 300;
  color: #FFFFFF;
  background-color: #052675;
}
.score {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #052675;
  margin-top: 5px;
}
.card-subtitle {
  position: relative;
  top: 15px;
  left: 7px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 20px;
  color: #052675;

}

.card-title-div-products {
  min-height: 80px;
  background: #D3E9FF;
  position: relative;
}
.card-title-div-products-description {
  min-height: 80px;
  position: relative;
  border-radius: 21px 21px 0px 0px;
}

.border-modal {
  border-radius: 21px 21px 0px 0px;
}

.product-description {
  display: inline;
  text-align: left;
  padding: 10px;
  overflow: hidden;
}

.card-text-div-products {
  position: relative;
  min-height: 300px;
  padding: 20px;
  padding-bottom: 2rem;
  top: 20px;
}
.description-body{
  min-height: 100%;
  min-width: 100%;
}
.header-modal-product {
  display: inline-block;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #4E4E4E;
  margin: 0px;
}
.header-dp{
  height: 96px;
}
.long-description {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
  color: #4E4E4E;
}
.moreInfo{
  margin-left: 5px;
}


@media (min-width: 40rem) {
  .products-cards-item {
    width: 50%;
  }
}

@media (min-width: 56rem) {
  .products-cards-item {
    width: 33.3333%;
  }
}

table tr th {
  color: #052675;
}

td {
  color: #052675;
}

.modal-size {
  min-width: 60%;
}

.div-modal-product {
  position: fixed;
  display: flex;
  flex-basis: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1060;
  width: 100%;
  outline: 0;
  background: #49505787;
  min-height: 60%;
  overflow: auto;
  justify-content: center;

}
.div-modal-product-description {
  position: fixed;
  display: flex;
  flex-basis: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1060;
  width: 100%;
  outline: 0;
  background: rgba(5, 38, 117, 0.9);
  min-height: 60%;
  overflow: auto;
  justify-content: center;

}

.modal-title-product {
  line-height: 1.2;
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 15px;
  text-align: center;
}
.title-dp{
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 24px;
color: #FFFFFF;
top: 20px;
position: relative;
}
.text-dp{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
  color: #4E4E4E;
  position: relative;
  top:20px;
  padding: 40px;
  
}

.modal-sub-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 110.2%;
  text-align: center;
  color: #052675;
  margin-left: 20px;
}

.modal-sub-text-note {
  color: #000;
  font-size: 12px;
  line-height: 1.2;
  font-weight: normal;
  position: absolute;
  left: 30px;
  top: 90%;
}

.modal-border-product {
  border: 1px dashed #052675;
  box-sizing: border-box;
  border-radius: 10px;
  background: white;
  min-width: 90%;
  box-shadow: 0px -4px 14px rgba(211, 233, 255, 0.5);

}
.modal-border-product-description {
  border: 1px dashed #D3E9FF;
  box-sizing: border-box;
  border-radius: 10px;
  min-width: 90%;
  background: transparent;
  padding: 70px 40px 70px 40px;
}
.border-dp {
  border-radius: 15px 15px 0 0;
}

.header-modal-product {
  background: #052675;
  border-radius: 10px 10px 0 0;
  color: white;
  text-align: center;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
}

.modal-content-product {
  overflow-y: auto;
}

.line-name {
  background-color: #B4EDFF;
  height: 0.5px;
  margin-top: 5px;
}

.colum-text {
  margin: 0;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  color: #052675;
}

.table-product {
  width: 100%;
  border-collapse: collapse;
  border: none;
  border-spacing: 0;
}

thead,
tbody {
  margin-bottom: 0px !important;
}

th {
  padding-bottom: 0px !important;
}

tr,
td {
  padding-top: 0px !important;
}

.table-product td,
.table-product th {
  text-align: left;
  border: none;
  color: #052675;
}

.table-product thead th {
  border-bottom: none;
}

.table-product th {
  background-color: white;
  color: black;
  border: none;
}

.table-product tbody tr:nth-child(even) {
  color: black;
}
.modal-border-description {
  background-color: transparent;
}

/*responsive table*/
@media (max-width: 600px) {
  .modal-dialog-product {
    height: auto;
    left: 0;
    /* position: fixed; */
    top: 0;
  }

  .div-modal-product {
    min-height: 100%;
  }
  .div-modal-product-description {
    min-height: 100%;

  }

  .modal-sub-text-note {
    top: 93%
  }

  .table-product thead {
    display: none;
  }

  .table-product,
  .table-product tbody,
  .table-product tr,
  .table-product td {
    display: block;
    width: 100%;
    border: none;
  }

  .table-product tr {
    margin-bottom: 20px;
    margin-top: 20px;

  }


  .table-product td {
    padding-left: 50%;
    text-align: left;
    margin-bottom: 1%;
    position: relative;
  }

  .hr-separator {
    display: none;
    background: 1px #052675;
  }

  .table-product td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    /*padding-left: 15px;
    margin-bottom: 10px;
    */
    padding-bottom: 20px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    line-height: 15px;
    color: #052675;
  }
}

.product-button-back {
  position: relative;
  float: right;
  width: 53px;
  height: 53px;
  background: #D3E9FF;
  box-shadow: inset 0px -3px 0px rgb(0 0 0 / 9%);
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 40px;
  margin-top: 30px;

}

.card-product {
  border: none;
  min-height: 395px;
}

.cards-products {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
}
.btn-spacing-product{
  margin-top: 15px;
}
.center-dp{
  display: flex;
  justify-content: center;
}
.w-dp {
  width: 300px;
}
.modal-description{
background: #FFFFFF;
border: 1px solid #D3E9FF;
box-sizing: border-box;
box-shadow: 4px 5px 4px rgba(0, 0, 0, 0.04);
border-radius: 21px;
}
.btn-close-dp{
border: 1px solid #D3E9FF;
box-sizing: border-box;
box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.086);
border-radius: 5px;
width: 42px;
height: 42px;
}
.dot {
  height: 65px;
  width: 65px;
  background-color: #D3E9FF;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-bottom: 25px;
  margin-left: 35px;
}

@media (min-width: 750px) {
  .cards-products {
    grid-template-columns: repeat(2, 1fr);
  }
}


@media (max-width: 750px) {

  .modal-border-product-description{
    padding: 10px;
  }
}
@media (max-width: 380px) {
.text-dp{
  max-height: 300px;
  overflow: auto;
}
}
@media (min-width: 1000px) {
  .cards-products {
    grid-template-columns: repeat(3, 1fr);
  }
}