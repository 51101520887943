.date-field{
   width: 50%;
}
.ellipse-left {
    position: absolute;
    left: 1%;
    top: 80%;
}
.ellipse-right {
    position: absolute;
    right: 1%;
    top: 110%;
}
.ellipse-top {
    position: absolute;
    right: 30%;
    top: 30%;
}
.card-question {
    margin-bottom: 0px; 
    border: none;
}
.card-body-question {
  padding: 0px;
}