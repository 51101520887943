.input-range {
   /* usually 30% of the div*/
   width: 30% !important;
   ;
}

/* when screen resolution is under 600px the date input will take 100% of the div*/
@media screen and (max-width: 600px) {
   .input-range {
      width: 100% !important;
      ;
   }
}

.numerical-i-matrix {
   float: right;
   margin-top: -45px;
   margin-right: 14px;
   padding-right: 5px;
  }