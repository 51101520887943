.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}

.width-container {
    width: 200px;
}

.bs-stepper .bs-stepper-content {
    padding: 0px;
}

.back-button {
    font-weight: 700;
    margin: 24px;
    background-color: #fff;
    color: #aaaaad;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.active .bs-stepper-circle {
    background-color: #D3E9FF !important;
}

.active .bs-stepper-circle {
    background-color: #e08d41 !important;
}

.bs-stepper .bs-stepper-content {
    margin-top: 2em !important;

}

.bs-stepper-circle {
    background-color: #052675 !important;
}

.container-custom::-webkit-scrollbar {
    width: 8px;
}


.container-custom::-webkit-scrollbar-thumb {
    background: #D3E9FF;
    border-radius: 4px;
    margin-bottom: 20em;
}

.container-custom::-webkit-scrollbar-thumb:active {
    background-color: #999999;
}

.container-custom::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.container-custom::-webkit-scrollbar-track {
    background: #e1e1e14f;
    border-radius: 4px;
}

.container-custom::-webkit-scrollbar-track:hover,
.container-custom::-webkit-scrollbar-track:active {
    background: #d4d4d4;
}