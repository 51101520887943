.card-div {
  position: relative;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #B4EDFF;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.card-title-div {
  height: 40px !important;
  max-height: 40px;
  padding-bottom: 1.5rem;
  margin-top: 1em;

}

.btn-select {
  position: absolute;
  height: 53px;
  top: 480px;
  left: 53px;
  margin-left: auto;
  margin-right: auto;
}

.card-text-div {
  position: relative;
  height: 126px;
  padding-bottom: 2rem;
  top: 20px;
  text-align: left;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  color: #000000;
}

.card-img-top {
  margin-top: 60px;
  margin-block-start: 20px;

}

.card-title-p {
  margin-top: 1%;
  font-size: 25px;
  font-weight: 300;
  color: #052675;
}

.title-wel {
  min-height: 23px;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 43px;
  text-align: center;
  color: #052675;
  margin-bottom: 5rem;
  padding-top: 1rem;
}

.title-wel-sub {
  height: 23px;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 43px;
  text-align: center;
  color: #052675;
  margin-bottom: 1rem;
}

@media (max-width: 380px) {
  .title-wel {
    font-size: 20px;

  }
}

.main {
  max-width: 1200px;
  margin: 0 auto;
}

.btn {
  text-transform: uppercase;
  width: 100%;
  cursor: pointer;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards-item {
  display: flex;
  padding: 1rem;
}

@media (min-width: 40rem) {
  .cards-item {
    width: 50%;
  }
}

@media (min-width: 56rem) {
  .cards-item {
    width: 33.3333%;
  }
}

.card {
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #B4EDFF;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.card-content {
  padding: 1rem;
}

.border-cards {
  margin: auto;
  width: 50%;
  padding: 10px;
  border: 1px dashed #D3E9FF;
  border-radius: 10px;
}
.card-footer-lp{
  position: relative;
  /* bottom: -130px; */
  min-width: 100%;
}
