.modal-header {
  border-bottom: none;
}

.info {
  fill: #FDC740;
}

.modal-border {
  border: 1px dashed #D3E9FF;
  box-sizing: border-box;
  border-radius: 10px;
  background: transparent;
}

.div-modal {
  position: fixed;
  float: left;
  top: 25%;
  left: 0;
  z-index: 1060;
  width: 100%;
  outline: 0;
  background: rgba(5, 38, 117, 0.9);
  height: 60%;
}

.modal-body {
  padding-left: 25px;
  padding-right: 25px;

}

.multiple-choise {
  text-align: left;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #D3E9FF;
  box-sizing: border-box;
  margin-top: 20px;
  border-radius: 10px !important;
}

input[type="checkbox"] {
  background-color: #D3E9FF;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 14px;
  height: 15px;
  margin-right: 15px;
  width: 15px;
  -webkit-appearance: none;
}

input[type="checkbox"]:after {
  border-radius: 25px;
  content: '';
  display: block;
  height: 7px;
  left: 4px;
  position: relative;
  top: 4px;
  width: 7px;

}

input[type="checkbox"]:checked:after {
  background-color: #052675;
}

.modal-content-multi {
  background-color: transparent;
  min-width: 500px;
}

.modal-p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #FFFFFF;
  text-align: justify;
  overflow: auto;
  max-height: 200px;
}

.modal-info {
  fill: #FDC740;
  position: absolute;
  left: 4.2em;
  top: 1.5em;
}

.modal-title {
  font-weight: bold;
  font-size: 18px;
  color: #FFFFFF;
  left: 4em;
  top: 3em;
  position: absolute;
  text-transform: uppercase;
}

.multi {
  width: 100%;
}

.custom_select__value-container {
  overflow: auto;
  max-height: 120px;
  display: block;
  width: 80%;
}

.basic-multi-select  .custom_select__indicators {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxjaXJjbGUgY3g9IjEyLjUiIGN5PSIxMi41IiByPSIxMi41IiB0cmFuc2Zvcm09InJvdGF0ZSgtOTAgMTIuNSAxMi41KSIgZmlsbD0iIzA1MjY3NSIvPg0KPHBhdGggZD0iTTYuMTk4MDcgMTAuMjQzN0wxMi4zNzY5IDE1LjgxMjdMMTguNTU1NyAxMC4yNDM3IiBzdHJva2U9IiNEM0U5RkYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg==");
  background-repeat: no-repeat;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-position-x: 95%;
  background-position-y: 15px;
  background-size: 25px 25px;
  width: 40px;
  height: 55px;
  margin-right: 10px;
  fill: transparent;
}

@media (min-width:400px) {
  .multi {
      width: 222px;
  }
}

/**@media (max-width: 790px) {
  .multi {
    width: 420px;
  }
}

@media (max-width: 525px) {
  .multi {
    width: 200px;
  }
}
*/

.basic-multi-select .custom_select__control {
  border: 1px solid #d3e9ff;
  border-radius: 10px;
}

.basic-multi-select .custom_select__control:hover {
  border: 1px solid #d3e9ff;
  box-shadow: none;
}

.basic-multi-select .custom_select__control--is-focused .custom_select__indicators {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxjaXJjbGUgY3g9IjEyLjUiIGN5PSIxMi41IiByPSIxMi41IiB0cmFuc2Zvcm09InJvdGF0ZSgtOTAgMTIuNSAxMi41KSIgZmlsbD0iIzA1MjY3NSIvPg0KPHBhdGggZD0iTTYuMTk4MDcgMTAuMjQzN0wxMi4zNzY5IDE1LjgxMjdMMTguNTU1NyAxMC4yNDM3IiBzdHJva2U9IiNEM0U5RkYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg==");
  background-repeat: no-repeat;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-position-x: 95%;
  background-position-y: 15px;
  background-size: 25px 25px;
  width: 40px;
  height: 55px;
}
.custom_select__indicator.custom_select__clear-indicator {
display: none;
}
.custom_select__indicator.custom_select__dropdown-indicator{
display: none;

}
.custom_select__indicator .custom_select__clear-indicators{
  margin-right: 10px;
}

.basic-multi-select .custom_select__control--is-focused {
  border: 1px solid #d3e9ff;
  box-shadow: none;
}




#multi-matrix.custom_select__control__invalid .custom_select__control {
  background-color: #F7F8F8;
  border: 1px dashed #F2274C;
  border-radius: 10px;
}

.custom_select__control__invalid .custom_select__control .custom_select__indicators {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzNyAzOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxlbGxpcHNlIGN4PSIxOC41IiBjeT0iMTkuMzk2NSIgcng9IjE4LjUiIHJ5PSIxOC40NDI4IiBmaWxsPSIjRjIyNzRDIi8+DQo8cGF0aCBkPSJNMTcgMjcuODgxNEMxNyAyNy40MjU5IDE3LjE2NzUgMjcuMDI5OSAxNy41MDI2IDI2LjY5MzJDMTcuODM3NyAyNi4zNTY2IDE4LjIzMTkgMjYuMTg4MyAxOC42ODUyIDI2LjE4ODNDMTkuMTM4NSAyNi4xODgzIDE5LjUzMjcgMjYuMzU2NiAxOS44Njc4IDI2LjY5MzJDMjAuMjAyOSAyNy4wMjk5IDIwLjM3MDQgMjcuNDI1OSAyMC4zNzA0IDI3Ljg4MTRDMjAuMzcwNCAyOC4zMzY4IDIwLjIwMjkgMjguNzIyOSAxOS44Njc4IDI5LjAzOThDMTkuNTMyNyAyOS4zMzY4IDE5LjEzODUgMjkuNDg1MyAxOC42ODUyIDI5LjQ4NTNDMTguMjMxOSAyOS40ODUzIDE3LjgzNzcgMjkuMzM2OCAxNy41MDI2IDI5LjAzOThDMTcuMTY3NSAyOC43MjI5IDE3IDI4LjMzNjggMTcgMjcuODgxNFpNMTguMzg5NiAyNC4xMzg4QzE4LjMxMDcgMjMuMjA4MSAxOC4yMjIgMjIuMjI3OSAxOC4xMjM1IDIxLjE5ODJDMTguMDQ0NiAyMC4xNDg3IDE3Ljk1NTkgMTkuMTE5IDE3Ljg1NzQgMTguMTA5MUMxNy43Nzg1IDE3LjA3OTQgMTcuNjk5NyAxNi4wOTkyIDE3LjYyMDkgMTUuMTY4NUMxNy41NjE3IDE0LjIzNzggMTcuNTAyNiAxMy40MDYxIDE3LjQ0MzUgMTIuNjczNEMxNy4zODQzIDExLjkyMSAxNy4zMzUxIDExLjMwNzEgMTcuMjk1NyAxMC44MzE5QzE3LjI1NjIgMTAuMzM2OCAxNy4yMzY1IDEwLjAzOTggMTcuMjM2NSA5Ljk0MDc3QzE3LjIzNjUgOS40MDYxMSAxNy4zODQzIDkuMDI5ODcgMTcuNjggOC44MTIwNUMxNy45NzU2IDguNTk0MjMgMTguMzEwNyA4LjQ4NTMyIDE4LjY4NTIgOC40ODUzMkMxOS4wNTk3IDguNDg1MzIgMTkuMzk0OCA4LjU5NDIzIDE5LjY5MDQgOC44MTIwNUMxOS45ODYxIDkuMDI5ODcgMjAuMTMzOSA5LjQwNjExIDIwLjEzMzkgOS45NDA3N0MyMC4xMzM5IDEwLjA5OTIgMjAuMDk0NSAxMC42NjM1IDIwLjAxNTYgMTEuNjMzOEMxOS45MzY4IDEyLjYwNDEgMTkuODM4MiAxMy43ODI0IDE5LjcyIDE1LjE2ODVDMTkuNjIxNCAxNi41NTQ2IDE5LjUwMzIgMTguMDQ5NyAxOS4zNjUyIDE5LjY1MzZDMTkuMjI3MiAyMS4yMzc4IDE5LjA5OTEgMjIuNzMyOCAxOC45ODA5IDI0LjEzODhIMTguMzg5NloiIGZpbGw9IndoaXRlIi8+DQo8L3N2Zz4NCg==");
  background-repeat: no-repeat;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-position-x: 95%;
  background-position-y: 15px;
  background-size: 25px 25px;
  fill: transparent;

}

