
footer{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 53px;
	background: #F9F9F9;
}
.footer__info{
	margin: auto;
    width: 90%;
    padding: 10px;
	text-align: center;
}
.row-f {
	margin-right: 0px;
	margin-left: 0px;
}
.terms-use,
.terms-site {
	color: rgba(5.000000176951289, 38.0000015348196, 117.00000062584877, 1);
	height: 32px;
	top: 11px;
	font-size: 14px;
	letter-spacing: 0;
	line-height: px;
	font-style: normal;
	font-weight: 400;
	text-decoration: underline rgba(5.000000176951289, 38.0000015348196, 117.00000062584877, 1);
	cursor: pointer;
}

.terms-s {
	color: rgba(78.46637472510338, 78.46637472510338, 78.46637472510338, 1);
	height: 32px;
	font-size: 12px;
	letter-spacing: 0;
	text-align: center;
	font-weight: 300;
	font-family: Open Sans;
	font-style: normal;
	pointer-events: none;
}

.line-footer {
	bottom: 0%;
	background: #D3E9FF;
	height: 30px;
}
.rectangle {
	bottom: 0%;
	background: #D3E9FF;
	height: 7px;
	width: 100%;

}
.c1, .c2, .c3, .c4{
	width: 100%;
	display: flex;
	justify-content: center;
}
.help-sticky__circle__footer {
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 3px;
    height: 23.69px;
    width: 23.69px;
    left: 228px;
    background: linear-gradient(0deg, #2D9CDB, #2D9CDB);
	}
.help-sticky{
	background: #052675;
	border: 2px solid #2D9CDB;
	border-radius: 36px;
	height: 31px;
	width: 241px;
	position: relative;

}

.help-sticky__text{

	left: 42.5%;
	right: 43.33%;
	top: 96.45%;
	bottom: 2.28%;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	color: #FFFFFF;
	text-align: center;
	margin-right: 5px;
    margin-left: 10px;

}
.help-sticky__circle {
    border-radius: 50%;
    display: inline-block;
    position: absolute;
	top: 5px;
    height: 23.69px;
    width: 23.69px;
	left: 228px;
    background: linear-gradient(0deg, #2D9CDB, #2D9CDB);
}

.help-sticky__circle_vector{
	position: absolute;
    left: 25%;
    top: 20%;
    bottom: 0%;
}

.terms-use:hover, .terms-site:hover {
	color: #052675;
}
.help-sticky__text:hover{
	color: white;
	cursor: pointer;
	
}
.control-display{
	display: none;
}

@media (min-width: 900px) {
	.c1
	 {
	  width: 23%;
	}
	.c2
	 {
	  width:10%;
	}
	.c3 {
	  width: 32%;
	}
	.c4 {
		width: 35%;
	  }
  }

  .btn-assistant-text-footer {
    display: inline-flex;
    border-radius: 36px;
    border: 2px solid #052675;
    background: #D3E9FF;
    color: #052675;
    font-weight: bold;
    font-size: 16px !important;
    top: 0%;
    Height: 35px;
    position: relative;
    width: 156px;
}
.help-text-footer {
    font-size: 15px !important;
    position: absolute;
    left: 32%;
    top: 16%;
}