.title-wel-sub {
  padding-block-end: 3rem;
}

.rider {
  border: 1px solid #D3E9FF;
  box-sizing: border-box;
  border-radius: 10px;
}

.not-risks-tittle {
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 43px;
  text-align: center;
  color: #052675;
  margin-bottom: 1rem;
}

.main-rider {
  padding-left: 50px;
  margin-right: 50px;
}

.is-invalid {
  min-height: 45px;
  border: 1px dashed #F2274C !important;
  box-sizing: border-box;
  border-radius: 10px;
}

.title-proyections {
  font-size: 20px;
  text-align: left;
  line-height: 43px;
  color: #052675;
  font-weight: bold;
}

.disclaimer-text {
  color: #052675;
  font-weight: bold;
}

.header-table-projections {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  list-style: none;
  background: #EBF5FF;
  border-radius: 8px;
}

.text-header {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #052675;
  margin-left: 40px;

}

.sub-header {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 20%;
  height: 127px;
  background: #D3E9FF;
  border-radius: 8px 0px 0px 8px;
}

.option-header-title {
  width: 30%;
}

.options-header {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 40%;
  height: 127px;
  border-radius: 8px 0px 0px 8px;
}

.header-separator-max {
  margin-left: 10%;
}

.detail-header {
  width: 70%;

}

.detail-p {
  padding-right: 20px;
}

.table .table-riders th {
  background-color: #052675;
  height: 96px;
  vertical-align: middle;
}

.table .table-riders-body td {
  height: 57px;
  text-align: center;
  vertical-align: bottom;
}

.color-tr {
  background: #D3E9FF;
}

.table .colum-table-rider {
  border-right: 1px dashed #052675;
  ;
}

.color-tr-white {
  background: #FFFFFF;
}

.proyections-container {
  margin-bottom: 100px;
}

.text-header-table {
  position: relative;
  top: 50px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  color: #052675;
  margin-left: 10px
}

.colum-text-rider {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  color: #FFFFFF;
}

.btn-image-xls {
  background: #FFFFFF;
  border: 1px solid #052675;
  width: 53px;
  height: 53px;
  position: absolute;
  border-radius: 0px 5px 5px 0px;
  left: 184px;
}


@media screen and (max-width: 600px) {
  .title-wel-sub-rider {
    margin-left: 0px;
  }

  .main-rider {
    padding-left: 0px;
    margin-right: 0px;
  }
}

.header-separator-min {
  display: none;
}

@media screen and (max-width: 1000px) {
  .options-header {
    width: 100%;
    flex-flow: row wrap;
  }

  .header-separator-max {
    display: none;
  }

  .header-separator-min {
    width: 100%;
  }

  .header-separator-min {
    display: block;
    width: 100%;
  }

  .detail-p {
    padding-left: 5px;
    font-size: 16px;
  }

  .detail-header {
    width: 100%;
  }

  .sub-header {
    width: 100%;
    justify-content: center;
    height: 50px;
  }

  .header-table-projection {
    height: 300px;
  }
}