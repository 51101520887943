@import"https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap";

*,
*::before,
*::after {
    box-sizing: border-box
}

body {
    color: #222;
    background-color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    position: relative;
    overflow-x: hidden
}
.cookie-law-info-bar {
    background-color: rgb(5, 38, 117);
    color: rgb(255, 255, 255);
    font-family: inherit;
    bottom: 0px;
    position: fixed;
    display: block;
}

.meduim-1 {
    display: inline-block;
    margin: 5px;
    color: rgb(255, 255, 255);
    background-color: rgb(5, 38, 117);
}

.icon-angle {
    display: none;
}

.back-home {
    color: #052675;
    font-size: 1.125rem;
    text-align: center;
}

.svg-icon--angle {
    stroke: #052675;
    fill: transparent;
}

.svg-icon--call {
    fill: #052675;
}

.container {
    max-width: 1160px;
    width: 100%;
    margin: auto;
}

.btn-landing {
    display: flex;
    align-items: center;
    width: 100%;
    box-shadow: inset 0px -3px #00000017;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    border: 0;
    padding: 0;
}
.btn-landing.cartbtn{
    position: inherit;
    height: 53px;
}
.btn-landing.stepbtn{
    position: initial;
    height: 53px;
}
.a-line {
    color: #FFFFFF !important;
    text-decoration: none !important;
}
.intro-carousel .box .icon.iconCard{
    background: no-repeat center;
    background-size: 100%;
    width: 70px;
    height: 70px;
    display: flex;
    margin-bottom: 0px;
}
.btn--center {
    width: max-content;
    margin-left: auto;
    margin-right: auto;
}

.btn--center>span {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.btn.sending {
    pointer-events: none;
    opacity: 0.6;
}

.btn.sending .icon .lds-dual-ring {
    visibility: visible;
    opacity: 1;
}

.btn.sending .icon .svg-icon {
    opacity: 0;
    visibility: hidden;
}

.btn:hover>span:after {
    width: 100%;
}

.btn>span {
    position: relative;
}

.btn>span span {
    box-shadow: none !important;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
}

.btn>span:after {
    content: "";
    height: 100%;
    width: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    transition: 0.3s;
    box-shadow: inset 0px -3px #00000017;
}

.btn>span:before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    box-shadow: inset 0px -3px #00000017;
}

.btn span {
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    box-shadow: inset 0px -3px #00000017;
    padding: 17px 0;
    text-align: center;
    flex: 1;
    transition: 0.3s;
    position: relative;
}
span .step{
    padding: 16px 0; 
}

.btn .icon {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.btn .icon .svg-icon {
    height: 12px;
    width: 20px;
    transform: rotate(-90deg);
}

.btn .icon .lds-dual-ring {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    right: -14px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
}

.btn-primary {
    background: #D3E9FF !important;
}

.btn-primary>span:before {
    background: #D3E9FF !important;
}
.btn-primary>span {
    background: #D3E9FF !important;
}

.btn-primary>span:after {
    background: #EAF4FF !important;
}

.btn-primary>span:active:after {
    background: #c1dfff !important;
}

.btn-primary span {
    color: #052675;
}

.btn-primary2 {
    background: #ffdb81;
}

.btn-primary2>span:before {
    background: #fdc740;
    border-radius: 5px 0px 0px 5px;
}

.btn-primary2>span:after {
    background: #ffdb81 !important;
    border-radius: 5px 0px 0px 5px;
}

.btn-primary2>span:active:after {
    background: #ffcf58 !important;
    border-radius: 5px 0px 0px 5px;
}

.btn-primary2 span {
    color: #052675;
}


.btn-primary3 {
    background: #c9e2fa;
}

.btn-primary3>span:before {
    background: #c9e2fa;
    border-radius: 5px 0px 0px 5px;
}

.btn-primary3>span:after {
    background: #c9e2fa !important;
    border-radius: 5px 0px 0px 5px;
}

.btn-primary3>span:active:after {
    background: #c9e2fa !important;
    border-radius: 5px 0px 0px 5px;
}

.btn-primary3 span {
    color: #052675;
}

.btn-primary22 {
    background: #ffdb81;
    left: 40%;
}

.btn-primary22>span:before {
    background: #fdc740;
}

.btn-primary22>span:after {
    background: #ffdb81 !important;
}

.btn-primary22>span:active:after {
    background: #ffcf58 !important;
}

.btn-primary22 span {
    color: #052675;
    font-weight: bold;
}

.btn-primary3 {
    background: transparent;
    box-shadow: inset 0px -3px transparent !important;
}
.btn-primary:hover {

}

.btn-secondary {
    background: #17398b;
}

.btn-secondary>span:before {
    background: #052675;
}

.btn-secondary>span:after {
    background: #17398b;
}

.btn-secondary span {
    color: #d3e9ff;
}

.btn-secondary .icon .svg-icon {
    stroke: #d3e9ff;
}

.btn-secondary.sending .lds-dual-ring:after {
    border-color: white transparent white transparent;
}

.btn-third {
    background: #F5FAFE;
}

.btn-third>span:before {
    background: #EDF1F5;
}

.btn-third>span:after {
    background: #F5FAFE;
}

.btn-third span {
    color: #052675;
}

.btn-third .icon .svg-icon {
    stroke: #052675;
}

.lds-dual-ring {
    display: inline-block;
    width: 40px;
    height: 40px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 4px;
    border-radius: 50%;
    border: 2px solid #052675;
    border-color: #052675 transparent #052675 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 560px) {

    .btn .icon {
        width: 47px;
    }
}

@media screen and (max-width: 1024px) {
    .btn-primary22 {
        left: 30%;
    }
}

@media screen and (max-width: 768px) {
    .btn-primary22 {
        left: 20%;
    }
}

@media screen and (max-width: 425px) {
    .btn-primary22 {
        left: 0%;
    }
}

/* Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    content: "";
    display: table;
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
}

[dir=rtl] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

/* Slider */
.slick-loading .slick-list {
    /*   background: #fff url("ajax-loader.gif") center center no-repeat; */
}


/* Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -25px;
}

[dir=rtl] .slick-prev {
    left: auto;
    right: -25px;
}

.slick-prev:before {
    content: "←";
}

[dir=rtl] .slick-prev:before {
    content: "→";
}

.slick-next {
    right: -25px;
}

[dir=rtl] .slick-next {
    left: -25px;
    right: auto;
}

.slick-next:before {
    content: "→";
}

[dir=rtl] .slick-next:before {
    content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    border: 0;
    background: transparent;
    display: block;
    height: 20px;
    width: 20px;
    outline: none;
    line-height: 0px;
    font-size: 0px;
    color: transparent;
    padding: 5px;
    cursor: pointer;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}

.slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "•";
    width: 20px;
    height: 20px;
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    text-align: center;
    color: black;
    opacity: 0.25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    color: black;
    opacity: 0.75;
}

.site-header {
    border-top: 6px solid #052675;
    padding: 1.375rem 0 1.375rem 0;
}

.site-header .site-logo {
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) {
    .site-header .site-logo {
        justify-content: start;
    }
}

.site-hero {
    background: #D3E9FF url(/public/images/bg-gradient.jpg) no-repeat center;
    background-size: cover;
}

@media (min-width: 768px) {
    .site-hero {
        background: #D3E9FF url(/public/images/img-hero.png) no-repeat center;
        background-size: cover;
        min-height: 640px;
    }
}

.site-hero .container {
    max-width: 1050px;
}

@media (min-width: 768px) {
    .site-hero .container {
        padding: 0 1rem;
    }
}

@media (min-width: 1024px) {
    .site-hero .container {
        padding: 0;
    }
}

.site-hero .mobile-img {
    margin-top: 3rem;
}

.site-hero .mobile-img img {
    width: 100%;
}

@media (min-width: 768px) {
    .site-hero .mobile-img {
        display: none;
    }
}

.site-hero .hero-content {
    max-width: 440px;
    padding: 50px 1rem 0 1rem;
}

@media (min-width: 768px) {
    .site-hero .hero-content {
        padding: 70px 0 0 0;
    }
}

.site-hero .hero-content .hero-title {
    color: #052675;
    font-weight: bold;
    font-size: 2.0625rem;
    margin-bottom: 2rem;
}

@media (min-width: 768px) {
    .site-hero .hero-content .hero-title {
        font-size: 3.125rem;
    }
}

.site-hero .hero-content p {
    color: #0A0A0A;
    font-size: 1.125rem;
    font-weight: 300;
    margin-bottom: 2rem;
}

.site-hero .hero-content .btn {
    display: inline-flex;
    width: auto;
    font-weight: bold;
    font-size: 1.25rem;
}

.site-hero .hero-content .btn>span {
    padding: 1rem 2rem;
}

.site-hero .hero-content .btn>span>span {
    font-weight: bold;
}

.site-footer {
    background: #F9F9F9;
    border-bottom: #D3E9FF 7px solid;
}

.site-footer .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 1rem;
}

@media (min-width: 768px) {
    .site-footer .container {
        flex-direction: row;
    }
}

.site-footer .col {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
}

@media (min-width: 768px) {
    .site-footer .col {
        flex-direction: row;
        width: 50%;
    }
}

.site-footer .col:first-child {
    margin-bottom: 1rem;
}

@media (min-width: 768px) {
    .site-footer .col:first-child {
        margin-bottom: 0;
    }
}

@media (min-width: 768px) {
    .site-footer .col:last-child {
        justify-content: end;
    }
}

.site-footer a {
    color: #052675;
    font-size: 0.875rem;
    text-decoration: underline;
}

.site-footer a:first-child {
    margin: 0 0 1rem 0;
}

@media (min-width: 768px) {
    .site-footer a:first-child {
        margin: 0 1rem 0 0;
    }
}

.site-footer p {
    color: #4E4E4E;
    font-size: 0.75rem;
}

.sec-intro {
    margin-bottom: 2rem;
}

@media (min-width: 768px) {
    .sec-intro {
        margin-top: -18rem;
        margin-bottom: 4rem;
    }
}

.sec-intro .container {
    max-width: 1100px;
}

.sec-intro .sec-title {
    align-items: center;
    color: #052675;
    font-size: 1.625rem;
    font-weight: bold;
    margin: 0 auto 1rem auto;
    position: relative;
    text-align: center;
    line-height: 1.2;
    max-width: 260px;
}

@media (min-width: 768px) {
    .sec-intro .sec-title {
        display: flex;
        font-size: 1.375rem;
        max-width: 100%;
        margin-bottom: 2rem;
    }
}

.sec-intro .sec-title::before {
    background: #2D9CDB;
    content: "";
    display: block;
    height: 7px;
    width: 50px;
    margin: 6px auto 2rem auto;
}

@media (min-width: 768px) {
    .sec-intro .sec-title::before {
        margin: 0 1.8rem 0 0;
    }
}

@media (min-width: 1100px) {
    .intro-carousel {
        margin-right: -1rem;
        margin-left: -1rem;
    }
}

.intro-carousel .slick-list {
    padding-bottom: 2rem;
}

.intro-carousel .slick-arrow {
    background: no-repeat center;
    background-size: 100%;
    top: -3rem;
    width: 32px;
    height: 32px;
}

.intro-carousel .slick-arrow::before {
    display: none;
}

.intro-carousel .slick-arrow:active {
    background-size: 98%;
}

.intro-carousel .slick-arrow.slick-prev {
    background-image: url(/public/images/arrow-prev-navy.svg);
    left: 1rem;
}

.intro-carousel .slick-arrow.slick-next {
    background-image: url(/public/images/arrow-next-navy.svg);
    right: 1rem;
}
.sec-intro .sec-title {
    align-items: center;
    color: #052675;
    font-size: 1.625rem;
    font-weight: bold;
    margin: 0 auto 1rem auto;
    position: relative;
    text-align: center;
    line-height: 1.2;
    max-width: 260px
}

.sec-intro .sec-title span {
    font-weight: 300
}
@media(min-width: 768px) {
    .sec-intro .sec-title {
        display: flex;
        font-size: 1.375rem;
        max-width: 40%;
        margin: 0;
        margin-bottom: 2rem;
        text-align: left
    }
}
.sec-intro .sec-title::before {
    background: #2d9cdb;
    content: "";
    display: block;
    height: 7px;
    width: 50px;
    margin: 6px auto 2rem auto
}

@media(min-width: 768px) {
    .sec-intro .sec-title::before {
        margin: 0 1.8rem 0 0
    }
}
.sec-options .sec-title {
    align-items: center;
    color: #052675;
    font-size: 1.875rem;
    font-weight: bold;
    margin: 0 auto 3rem auto;
    position: relative;
    text-align: center;
    line-height: 1.2
}

.sec-options .sec-title span {
    font-weight: 300
}
.sec-faqs {
    margin-bottom: 5rem
}

.sec-faqs .sec-title {
    text-align: center;
    margin-bottom: .6rem;
    font-size: 1.875rem;
    font-weight: bold;
    color: #052675
}


.intro-carousel .li-stick {
    margin-bottom: 140px;
}
.intro-carousel .box .desc p.opt {
    margin-top: 1rem;
    color: #052675
}

.intro-carousel .box {
    box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.07);
    background: white;
    padding: 2.5rem;
    margin: 0 1rem;
    height: 450px;
}

.intro-carousel .box .icon {
    background: no-repeat center;
    background-size: 100%;
    width: 70px;
    height: 70px;
    display: block;
    margin-bottom: 1.5rem;
}

.intro-carousel .box .icon.icon-1 {
    background-image: url(/public/images/icon-intro-h1.svg);
}

.intro-carousel .box .icon.icon-2 {
    background-image: url(/public/images/icon-intro-h3.svg);
}

.intro-carousel .box .icon.icon-3 {
    background-image: url(/public/images/icon-intro-h2.svg);
}
.last-of-type {
    font-size: 1.1rem !important;
}
.intro-carousel .box .title {
    color: #052675;
    font-weight: bold;
    font-size: 1.375rem;
    margin-bottom: 1rem;
    line-height: 1.3;
    height: 55px;
}

.intro-carousel .box .desc {
    position: relative;
    padding-top: 1.4rem;
    height: 160px;
}
.intro-carousel .box .desc p {
    font-size: 0.875rem;
}


.intro-carousel .box .desc::before {
    background: #052675;
    content: "";
    display: block;
    height: 2px;
    margin-right: 1.8rem;
    width: 50px;
    top: 0;
    position: absolute;
}

.intro-carousel .box p {
    font-size: 0.875rem;
}

.sec-statistics {
    background: #052675;
    padding: 2rem 1rem;
    color: white;
    margin-bottom: 5rem;
}

ul.statistics-list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

@media (min-width: 768px) {
    ul.statistics-list {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

ul.statistics-list li {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto 3rem auto;
    max-width: 200px;
}

@media (min-width: 768px) {
    ul.statistics-list li {
        max-width: 300px;
        width: 50%;
    }
}

@media (min-width: 1024px) {
    ul.statistics-list li {
        margin: 0 auto;
        width: 16%;
    }
}

ul.statistics-list li .icon {
    background: no-repeat center;
    background-size: 100%;
    width: 90px;
    height: 90px;
    display: block;
    margin: 0 auto 1rem auto;
}

@media (min-width: 768px) {
    ul.statistics-list li .icon {
        margin: 0 auto 1.5rem auto;
    }
}

ul.statistics-list li .icon.icon-1 {
    background-image: url(/public/images/icon-stat-1.svg);
}

ul.statistics-list li .icon.icon-2 {
    background-image: url(/public/images/icon-stat-2.svg);
}

ul.statistics-list li .icon.icon-3 {
    background-image: url(/public/images/icon-stat-3.svg);
}

ul.statistics-list li .icon.icon-4 {
    background-image: url(/public/images/icon-stat-4.svg);
}

ul.statistics-list li .icon.icon-5 {
    background-image: url(/public/images/icon-stat-5.svg);
}

ul.statistics-list li .icon.icon-6 {
    background-image: url(/public/images/icon-stat-6.svg);
}

ul.statistics-list li h3 {
    font-weight: bold;
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
}

ul.statistics-list li p {
    font-size: 0.8125rem;
    font-weight: 700;
}

.sec-get-insurance {
    margin-bottom: 5rem;
}

.sec-get-insurance>.container {
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px) {
    .sec-get-insurance>.container {
        flex-direction: row-reverse;
    }
}

.sec-get-insurance>.container .sec-figure {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
    width: 100%;
}

@media (min-width: 768px) {
    .sec-get-insurance>.container .sec-figure {
        width: 30%;
    }
}

@media (min-width: 1024px) {
    .sec-get-insurance>.container .sec-figure {
        width: 40%;
    }
}

.sec-get-insurance>.container .sec-figure img {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
}

.sec-get-insurance>.container .sec-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1rem;
}

@media (min-width: 768px) {
    .sec-get-insurance>.container .sec-content {
        width: 70%;
        padding: 0;
    }
}

@media (min-width: 1024px) {
    .sec-get-insurance>.container .sec-content {
        width: 60%;
    }
}

.sec-get-insurance>.container .sec-content .sec-title {
    font-weight: bold;
    font-size: 1.875rem;
    margin: 0 auto 4rem auto;
    color: #052675;
    text-align: center;
    max-width: 320px;
}

@media (min-width: 768px) {
    .sec-get-insurance>.container .sec-content .sec-title {
        font-size: 2.1875rem;
        text-align: left;
        max-width: 100%;
    }
}

@media (min-width: 1024px) {
    .sec-get-insurance>.container .sec-content .sec-title {
        margin: 0 0 4rem 0;
    }
}

.sec-get-insurance>.container .sec-content .step-content .title {
    color: #2D9CDB;
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 2rem;
}

.sec-get-insurance>.container .sec-content .step-content .subtitle {
    font-weight: normal;
    font-size: 1.6875rem;
    margin-bottom: 2rem;
    color: #052675;
}

.sec-get-insurance>.container .sec-content .step-content .desc {
    margin-bottom: 2rem;
}

.sec-get-insurance>.container .sec-content .step-content .desc p {
    color: #052675;
    font-size: 1.125rem;
}

.sec-get-insurance>.container .sec-content .step-content .btn {
    width: auto;
    display: inline-flex;
}

.sec-get-insurance>.container .sec-content .step-content .btn>span {
    padding: 1rem 2rem;
}

.sec-get-insurance>.container .sec-content .step-content .btn>span>span {
    font-weight: bold;
}

.steps-carousel {
    margin-top: 2rem;
}

.steps-carousel .slick-dots {
    bottom: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top: -3rem;
}

.steps-carousel .slick-dots li {
    background: #D3E9FF;
    height: 4px;
    width: 20%;
}

.steps-carousel .slick-dots li.slick-active {
    background: #052675;
}

.steps-carousel .slick-dots li button {
    height: 4px;
    width: 100%;
}

.steps-carousel .slick-dots li button::before {
    display: none;
}

.sec-faqs {
    margin-bottom: 5rem;
}

.sec-faqs .sec-title {
    text-align: center;
    margin-bottom: 0.6rem;
    font-size: 1.875rem;
    font-weight: bold;
    color: #052675;
}

@media (min-width: 768px) {
    .sec-faqs .sec-title {
        font-size: 2.8125rem;
    }
}

.sec-faqs .sec-subtitle {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.375rem;
    font-weight: normal;
    color: #052675;
}

@media (min-width: 768px) {
    .sec-faqs .sec-subtitle {
        margin-bottom: 3rem;
        font-size: 2.1875rem;
    }
}



.faqs-carousel .slick-arrow {
    background: url(/public/images/arrow-prev-blue.svg) no-repeat center;
    background-size: 100%;
    width: 40px;
    height: 40px;
    position: relative;
    top: 200px;
}

.faqs-carousel .slick-arrow::before {
    display: none;
}

.faqs-carousel .slick-arrow:active {
    background-image: url(/public/images/arrow-prev-navy.svg);
}

.faqs-carousel .slick-arrow.slick-prev {
    left: 50%;
}

.faqs-carousel .slick-arrow.slick-next {
    transform: rotate(-180deg);
    right: 0px;
    top: 182.5px;
}

.faqs-carousel .box {
    padding: 2rem 2rem 1rem 2rem;
    position: relative;
    text-align: center;
}

@media (min-width: 768px) {
    .faqs-carousel .box {
        text-align: left;
        padding: 3rem 2rem 1rem 2rem;
    }
}

.faqs-carousel .box::before {
    background: #2D9CDB;
    content: "";
    height: 7px;
    width: 90px;
    position: absolute;
    left: 38%;
    top: 0;
}

@media (min-width: 768px) {
    .faqs-carousel .box::before {
        left: 2rem;
    }
}

.faqs-carousel .box h3 {
    font-size: 2.25rem;
    font-weight: bold;
    color: #052675;
    margin-bottom: 2rem;
}

.faqs-carousel .box h4 {
    font-size: 1.25rem;
    font-style: italic;
    font-weight: normal;
    color: #052675;
    margin-bottom: 1.5rem;
    line-height: 1.2;
}

.page-content {
    margin: 3rem 1rem;
}

.page-content a {
    color: #008fcd;
}

.page-content a:hover {
    color: #17398b;
}

.page-content a.go-home {
    font-weight: normal;
    font-size: 1.125rem;
    display: inline-flex;
    margin-bottom: 2rem;
    padding: 0.25rem 0;
}

.page-content h4 {
    margin-bottom: 1.5rem;
}

.page-content .page-header {
    margin-bottom: 3rem;
}

.page-content .page-header .page-title {
    color: #052675;
    font-weight: bold;
    font-size: 2.5rem;
}

.page-content .page-main p {
    margin-bottom: 1rem;
    line-height: 1.6;
}

.page-content .page-main ul,
.page-content .page-main ol {
    margin-bottom: 1rem;
}

.page-content .page-main ul li,
.page-content .page-main ol li {
    line-height: 1.6;
    margin-bottom: 0.6rem;
}

.page-content .page-main ul li {
    background: url(/public/images/bullet-blue.svg) no-repeat left 0.8rem;
    background-size: 5px;
    padding-left: 1rem;
}

#cookie-law-info-bar {
    font-size: 15px;
    margin: 0 auto;
    padding: 12px 10px;
    position: absolute;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    z-index: 9999;
    /* box-shadow:rgba(0,0,0,.5) 0px 5px 50px; */
    display: none;
    left: 0px;
    font-weight: 300;
    box-shadow: 0 -1px 10px 0 rgba(172, 171, 171, 0.3);
}

#cookie-law-info-again {
    font-size: 10pt;
    margin: 0;
    padding: 5px 10px;
    text-align: center;
    z-index: 9999;
    cursor: pointer;
    box-shadow: #161616 2px 2px 5px 2px;
}

#cookie-law-info-bar span {
    vertical-align: middle;
}

/** Buttons (http://papermashup.com/demos/css-buttons) */
.cli-plugin-button {
    display: inline-block;
    padding: 9px 12px;
    color: #fff;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    margin-left: 5px;
    text-decoration: none;
}

.cli-plugin-button:visited {
    display: inline-block;
    padding: 9px 12px;
    color: #fff;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    margin-left: 5px;
    text-decoration: none;
}

.cli-plugin-main-link {
    margin-left: 0px;
    font-weight: 550;
    text-decoration: underline;
}

.cli-plugin-button:hover {
    background-color: #111;
    color: #fff;
    text-decoration: none;
}

.small.cli-plugin-button {
    font-size: 11px;
}

.small.cli-plugin-button:visited {
    font-size: 11px;
}

.cli-plugin-button,
.medium.cli-plugin-button {
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
}

.cli-plugin-button:visited,
.medium.cli-plugin-button:visited {
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
}

.large.cli-plugin-button {
    font-size: 14px;
    padding: 8px 14px 9px;
}

.large.cli-plugin-button:visited {
    font-size: 14px;
    padding: 8px 14px 9px;
}

.super.cli-plugin-button {
    font-size: 34px;
    padding: 8px 14px 9px;
}

.super.cli-plugin-button:visited {
    font-size: 34px;
    padding: 8px 14px 9px;
}

.pink.cli-plugin-button,
.magenta.cli-plugin-button:visited {
    background-color: #e22092;
}

.pink.cli-plugin-button:hover {
    background-color: #c81e82;
}

.green.cli-plugin-button {
    background-color: #91bd09;
}

.green.cli-plugin-button:visited {
    background-color: #91bd09;
}

.green.cli-plugin-button:hover {
    background-color: #749a02;
}

.red.cli-plugin-button {
    background-color: #e62727;
}

.red.cli-plugin-button:visited {
    background-color: #e62727;
}

.red.cli-plugin-button:hover {
    background-color: #cf2525;
}

.orange.cli-plugin-button {
    background-color: #ff5c00;
}

.orange.cli-plugin-button:visited {
    background-color: #ff5c00;
}

.orange.cli-plugin-button:hover {
    background-color: #d45500;
}

.blue.cli-plugin-button {
    background-color: #2981e4;
}

.blue.cli-plugin-button:visited {
    background-color: #2981e4;
}

.blue.cli-plugin-button:hover {
    background-color: #2575cf;
}

.yellow.cli-plugin-button {
    background-color: #ffb515;
}

.yellow.cli-plugin-button:visited {
    background-color: #ffb515;
}

.yellow.cli-plugin-button:hover {
    background-color: #fc9200;
}

.cli-plugin-button {
    margin-top: 5px;
}

.cli-bar-popup {
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    padding: 20px;
}

.cli-powered_by_p {
    width: 100% !important;
    display: block !important;
    color: #333;
    clear: both;
    font-style: italic !important;
    font-size: 12px !important;
    margin-top: 15px !important;
}

.cli-powered_by_a {
    color: #333;
    font-weight: 600 !important;
    font-size: 12px !important;
}

/**
   * Added extra space between each cookie bar action elements @since 1.8.9
   */
.cli-plugin-main-link.cli-plugin-button {
    text-decoration: none;
    margin-left: 5px;
}

#cookie-law-info-bar {
    background: rgba(5, 38, 117, 0.95) !important;
    text-align: center;
    padding: 2rem 1rem;
}

#cookie-law-info-bar a {
    color: #048fcb;
}

#cookie-law-info-bar p {
    display: block;
    font-size: 0.6rem;
    font-weight: normal;
    line-height: 1.4;
    max-width: 700px;
    margin: 0 auto;
}

#cookie-law-info-bar #cookie_action_close_header {
    border: 1px solid #fff;
    border-radius: 6px;
    font-weight: bold;
    font-size: 0.6rem;
    padding: 0.8rem 3rem;
}

#cookie-law-info-again {
    background: rgba(5, 38, 117, 0.65) !important;
    box-shadow: inherit;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 0.75rem;
}

body.has-cookie-modal::before {
    content: " ";
    background: rgba(255, 255, 255, 0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9998;
}

ul {
    list-style-type: none;
}

.ul-stick {
    display: flex;
    flex-wrap: wrap;
}

.li-stick {
    flex: auto;
    margin: 10px;
    width: 330px;
    height: 330px;
}

.img-ad {
    background-image: url(/public/images/img-adquiere-tu-seguro.png) no-repeat center center;
}

/*# sourceMappingURL=style.css.map */

.step-content-2 {
    display: none;
}

.bar-btn {
    height: 4px;
    width: 100%;
    z-index: 1000;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background-color: #EAF4FF !important;
    border-color: #EAF4FF !important;
}
.btn-primary.focus, .btn-primary:focus {
    background-color: #EAF4FF !important;
    border-color: #EAF4FF !important;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
    box-shadow: none;
}

.st-btn {
    border: 0;
    background: transparent;
    display: block;
    height: 20px;
    width: 20px;
    outline: none;
    line-height: 0px;
    font-size: 0px;
    color: transparent;
    padding: 5px;
    cursor: pointer;
}

.st-btn-active {
    background: #052675;
    content: "";
    height: 7px;
    width: 18%;
    display: inline-block;
    margin-right: 10px;
}

.st-btn-inactive {
    background: #D3E9FF;
    ;
    content: "";
    height: 7px;
    width: 18%;
    display: inline-block;
    margin-right: 10px;
}

@keyframes slidein {
    from {
        margin-left: 5%;
        width: 100%
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

.entrade-left {
    animation-duration: 1s;
    animation-name: slidein;
    animation-iteration-count: 1;
}

.img-sec {
    background-image: url(/public/images/img-adquiere-tu-seguro.png);

}

.sec-content {
    height: 650px;
}
.faqs-s {
display: flex;
}
.backtBtn-stick {
    width: 10%;
}
.slider-fqs {
    width: 80%;
}
.nextBtn-stick {
    width: 10%;
}
.sec-options {
    margin-bottom: 2rem;
}
.flex-row-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.flex-row-container > .flex-row-item {
    flex: 1 1 30%; /*grow | shrink | basis */
    margin-bottom: 1rem;
    
}
.box-content {
    padding: 25px;
}
.sec-options .box {
    border-radius: 22px;
    border: 1px solid #052675;
    background: white;
    margin: 0 1rem;
    height: 370px;
    position: relative;
    min-width: 305px;
    }
    .sec-options .icon {
        background: no-repeat center;
        background-size: contain;
        width: 96px;
        height: 89px;
        display: block;
        margin: 0 auto 1.5rem auto;
    }  
    .icon.icon-1 { 
        background-image: url(/public/images/icon-opt-1.svg);
    }
    .icon.icon-2 { 
        background-image: url(/public/images/icon-opt-2.svg);
    }
    .icon.icon-3 { 
        background-image: url(/public/images/icon-opt-3.svg);
    }
    .sec-options .title {
        color: #052675;
        font-weight: 300;
        font-size:  1.25rem;
        margin-bottom: 1rem;
        line-height: 1.3;
        text-align: center;
      }
  

@media(min-width: 768px) {
    .sec-options {
        margin-bottom: 4rem
    }
}

.sec-options {
    margin-bottom: 2rem
}

@media(min-width: 768px) {
    .sec-options {
        margin-bottom: 4rem
    }
}

.sec-options .container {
    max-width: 1050px;
    min-height: 500px;
}

.sec-options .sec-title {
    align-items: center;
    color: #052675;
    font-size: 1.875rem;
    font-weight: bold;
    margin: 0 auto 3rem auto;
    position: relative;
    text-align: center;
    line-height: 1.2
}

.sec-options .sec-title span {
    font-weight: 300
}
.sec-options .desc p{
 
        text-align: center;
        font-size: 1rem;
}
    
.sec-options .desc p::after{
        content: '';
        display: block;
        width: 50px;
        height: 5px;
        background: #052675;
        position: absolute;
        bottom: 1.5rem;
        left: 50%;
        margin-left: -25px;
}