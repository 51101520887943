*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.body {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    background: #E5E5E5;

}
#root{
min-width: 320px;
}
.titles {
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: center;
}

.letters {
    font-weight: 300;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 23px;
    padding: 1px;

}

.checkbox-container {
    display: flex;
    align-items: center;
}

.title-card {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 1px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #052675;
}
.title-card-r {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 1px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #052675;
}

input[type=checkbox] {
    -ms-transform: scale(2);
    -moz-transform: scale(2);
    -webkit-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(2);
    padding: 10px;
}

input[type=radio] {
    -ms-transform: scale(2);
    -moz-transform: scale(2);
    -webkit-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(2);
    padding: 10px;
    border: 0.2px solid #052675;

}
input[type=radio] + label {
    color: black;
}
input[type=radio]:checked {
    border: none;
}
@media (max-width: 499px) {
    .options {
        font-size: 12px;
        line-height: 20px;
    }
}

:checked+label {
    font-weight: bold;
}

.error {
    color: #F2274C;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    font-weight: bold;

}
.input-rider {
    height: 45px!important;
}

.form-control.custom-input {
    background-color: #f6fbff;
    font-weight: bold;
    color: #052675;
    border: 2px;
    box-sizing: border-box;
    border-radius: 10px;
}
.form-control.custom-input:focus {
    background-color: #f6fbff;
    box-shadow: 0 0 0 0.2rem rgba(208, 216, 222, 0.413);
}

.control-matrix .form-control:focus {
    background-color: white;
    box-shadow: none;
    border: 1px solid #d3e9ff ;
}

.control-matrix .form-control {
    font-weight: bold;
    color: #052675;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #d3e9ff ;
}

select{
    min-width: 190px;
}
select option {
    font-weight: bold;
    background: white;
    color: #052675;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.info {
    padding: 1px;
    fill: #FDC740;
}

.is-invalid-border{
    border: 1px dashed #F2274C;
    box-sizing: border-box;
    border-radius: 10px;
    min-height: 89px;
    padding: 12px 12px;

}

.form-control.custom-input.is-invalid-input {
    background-color: #F7F8F8;
    padding: 12px 12px;
}

.control-matrix .is-invalid-input {
    background-color: #F7F8F8;
    border: 1px dashed #F2274C;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px 12px;
}


input+i {
    float: right;
    margin-top: -50px;
    margin-right: 1px;
    pointer-events: none;
    padding-right: 5px;
 
}

textarea+i {
    float: right;
    margin-top: -45px;
    margin-right: 1px;
    pointer-events: none;
    padding-right: 5px;
    position: relative;
}
.textarea-i {
    float: right;
    margin-top: -55px;
    margin-right: 1px;
    pointer-events: none;
    padding-right: 5px;
    position: relative;
}

.form-control:disabled,
.form-control[readonly] {
    opacity: 1;
    background-color: #e9ecef !important;
}

.border-div-input {
    border: 1px dashed #D3E9FF;
    height: 89px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px 12px;
}

.border-div-yn {
    border: 1px dashed #D3E9FF;
    height: 180px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px 12px;
}

.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.height-input {
    min-height: 65px;
}

.height-input-matrix {
    min-height: 60px;
}
.modal-matrix {
    margin-top: -95px;
    left: 177px;
    padding-right: 5px;
    position: absolute;
}
.modal-icon {
    right:40px;
    position:absolute;
    top:30%;
}
.select-i-rider {
    float: right;
    margin-top: -38px;
    margin-right: 14px;
    padding-right: 1px;
}

.input-i-rider {
    float: right;
    margin-top: -36px;
    margin-right: 14px;
    padding-right: 1px;
}
.long-i-matrix {
    margin-top: -45px;
    right: 400px;
    padding-right: 5px;
    position: absolute;
}

.long-modal::before {
    position: absolute;
    height: 25px;
    width: 25px;
    top: 50%;
    transform: translate(-50%, -50%);
    right: 0rem;
    content: "";
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxjaXJjbGUgY3g9IjEyLjUiIGN5PSIxMi41IiByPSIxMi41IiBmaWxsPSIjRkRDNzQwIi8+DQo8cGF0aCBkPSJNMTEuNDg2MyAxOC4yNTA4QzExLjQ4NjMgMTcuOTQyMSAxMS41OTk1IDE3LjY3MzcgMTEuODI1OSAxNy40NDU1QzEyLjA1MjMgMTcuMjE3NCAxMi4zMTg3IDE3LjEwMzMgMTIuNjI1IDE3LjEwMzNDMTIuOTMxMyAxNy4xMDMzIDEzLjE5NzYgMTcuMjE3NCAxMy40MjQgMTcuNDQ1NUMxMy42NTA0IDE3LjY3MzcgMTMuNzYzNiAxNy45NDIxIDEzLjc2MzYgMTguMjUwOEMxMy43NjM2IDE4LjU1OTUgMTMuNjUwNCAxOC44MjEyIDEzLjQyNCAxOS4wMzU5QzEzLjE5NzYgMTkuMjM3MyAxMi45MzEzIDE5LjMzNzkgMTIuNjI1IDE5LjMzNzlDMTIuMzE4NyAxOS4zMzc5IDEyLjA1MjMgMTkuMjM3MyAxMS44MjU5IDE5LjAzNTlDMTEuNTk5NSAxOC44MjEyIDExLjQ4NjMgMTguNTU5NSAxMS40ODYzIDE4LjI1MDhaTTEyLjQyNTIgMTUuNzE0MkMxMi4zNzE5IDE1LjA4MzQgMTIuMzEyIDE0LjQxOTEgMTIuMjQ1NCAxMy43MjExQzEyLjE5MjIgMTMuMDA5OCAxMi4xMzIyIDEyLjMxMTkgMTIuMDY1NiAxMS42Mjc0QzEyLjAxMjQgMTAuOTI5NSAxMS45NTkxIDEwLjI2NTIgMTEuOTA1OCA5LjYzNDM5QzExLjg2NTkgOS4wMDM2IDExLjgyNTkgOC40Mzk5IDExLjc4NiA3Ljk0MzMyQzExLjc0NiA3LjQzMzMxIDExLjcxMjcgNy4wMTcyNiAxMS42ODYxIDYuNjk1MTVDMTEuNjU5NSA2LjM1OTYyIDExLjY0NjEgNi4xNTgzIDExLjY0NjEgNi4wOTExOUMxMS42NDYxIDUuNzI4ODIgMTEuNzQ2IDUuNDczODIgMTEuOTQ1OCA1LjMyNjE5QzEyLjE0NTUgNS4xNzg1NSAxMi4zNzE5IDUuMTA0NzQgMTIuNjI1IDUuMTA0NzRDMTIuODc4IDUuMTA0NzQgMTMuMTA0NCA1LjE3ODU1IDEzLjMwNDIgNS4zMjYxOUMxMy41MDM5IDUuNDczODIgMTMuNjAzOCA1LjcyODgyIDEzLjYwMzggNi4wOTExOUMxMy42MDM4IDYuMTk4NTYgMTMuNTc3MiA2LjU4MTA3IDEzLjUyMzkgNy4yMzg3MUMxMy40NzA3IDcuODk2MzUgMTMuNDA0MSA4LjY5NDkxIDEzLjMyNDIgOS42MzQzOUMxMy4yNTc2IDEwLjU3MzkgMTMuMTc3NyAxMS41ODcyIDEzLjA4NDQgMTIuNjc0M0MxMi45OTEyIDEzLjc0OCAxMi45MDQ3IDE0Ljc2MTMgMTIuODI0NyAxNS43MTQySDEyLjQyNTJaIiBmaWxsPSIjMDUyNjc1Ii8+DQo8L3N2Zz4=");
    background-size: contain;
  
  }

