.sub-rider-list {
  font-weight: bold;
  padding-bottom: 2rem;
  color: #052675;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
}
.riders-lists4{
  background: #FFFFFF;
  border: 1px dashed #D3E9FF;
  border-radius: 10px;
  width: 100%;
  background-color: white;
  padding: 40px;
  height: 240px;
}
.load{
  min-height: 350px;
}
.redirect-text {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 50px;
  font-family: Open Sans;
  font-style: bold;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #052675;
  background-color: rgba(211, 233, 255, 0.2);
}
.rider-text {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 50px;
  font-family: Open Sans;
  font-style: normal;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #052675;
  background-color: rgba(211, 233, 255, 0.2);
}

.rider-amount {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 100px;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 10px;
  text-align: center;
  color: #052675;
}

.riders-list {
  background: #FFFFFF;
  border: 1px dashed #D3E9FF;
  border-radius: 10px;
  width: 100%;
  background-color: white;
  padding: 40px;
}

.container-amount,
.container-rider-amount {
  width: 100%;
}
.container-rider-amount-premium{
  display: none;
  margin-left: 32px;

}

.amount {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  margin-top: 10px;
  line-height: 2rem;
  text-align: center;
  color: #052675;
}

.select-payment {
  padding-bottom: 0.5rem;
}

.title-wel-sub-rider {
  top: 279px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 33px;
  text-align: left;
  color: #052675;
  margin-left: 85px;
}

.div-riders {
  background: #FFFFFF;
  border: 1px dashed #D3E9FF;
  border-radius: 10px;
}

.title-rider {
  width: 49%;
  height: 43px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold !important;
  font-size: 20px;
  line-height: 23px;
  color: #052675;
}

.title-rider-min {
  width: 43%;
  height: 43px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #052675;
}


.title-rider-premium{
  width: 8%;
  height: 43px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #052675;
}

.back-button {
  text-align: right;
}

.not-risks-tittle {
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 43px;
  text-align: center;
  color: #052675;
  margin-bottom: 1rem;

}

.input {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  text-align: justify;
  color: #052675;
}

.button-back-rider {
  position: relative;
  width: 53px;
  height: 53px;
  background: #D3E9FF;
  box-shadow: inset 0px -3px 0px rgb(0 0 0 / 9%);
  border-radius: 5px;
  margin-right: 20px;
}

.button-next-rider {
  position: relative;
  min-width: 374px;
  margin-right: 10px;
}
.redirect {
  justify-content: end;
  margin-top: 10px;
  display: flex;
}
div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}

.bottons--container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  justify-content: flex-end;
}
.bottons--container-rider {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  justify-content: center;
}

.ellipse-top {
  top: 24%;
}

@media (min-width: 900px) {

  .container-amount,
  .container-rider-amount {
    width: 43%;
  }
  .container-rider-amount-premium{
    display: flex;

  }

  .amount {
    text-align: right;
  }

  .rider-amount {
    text-align: left;
    margin-left: 10px;
  }
}