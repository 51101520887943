.yes-not {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #D3E9FF;
    box-sizing: border-box;
    border-radius: 10px !important;
}



@media (min-width:400px) {
    .yes-not {
        width: 100%;
    }
}

@media (min-width:600px) {
    .yes-not {
        width: 100%;
    }
}

@media (min-width:900px) {
    .yes-not {
        width: 100%;
    }
}

input[type="radio"] {
    background-color: #D3E9FF;
    border-radius: 10px;
    cursor: pointer;
    height: 15px;
    margin-right: 15px;
    width: 15px;
    -webkit-appearance: none;
}

input[type="radio"]:after {
    border-radius: 25px;
    content: '';
    display: block;
    height: 7px;
    left: 4px;
    position: relative;
    top: 4px;
    width: 7px;

}

input[type="radio"]:checked:after {

    background-color: #052675;
}

.radio-label {
    position: absolute !important;
    left: 80px;
    top: 12px;
}

.modal-icon-yn {
    right: 40px;
    position: absolute;
    top: 15%;
}
.yesno-matrix {
    display: inline-block;
    padding: 10px;
}

.border-div-yn-matrix {
    background: #f6fbff;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 8px 8px;
}

.yn-is-invalid-border {
    border: 1px dashed #F2274C;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 8px 8px;

}