.amount-slider {
    font-size: 0.875rem;
    line-height: 2rem;
    text-align: justify;
    color: #052675;
}
.title-premiun{
    display: flex;
    margin-left: 10px;
    margin-top: 6px;
}
.value-premiun{
    display: none;
}
.text-overflow{
    word-wrap: break-word;
}
.ml-error{
    margin-left: 20px;
}

.amount-text, .amount-text-slider {

    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 32px;
    text-align: justify;
    color: #052675;
}

.slider-row {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 0px 0px 15px;
    height: auto;     
}

.slider-text {
    padding: 0px 0px 0px 11px;
}

.slider-input {
    display: flex;
    flex-wrap: wrap;
}

.input-div-text {
    font-family: Open Sans;
}

.tooltip-input {
    width: 10%;
    margin-top: 25px;
    margin-left: 5px;
}
.premium{
    width: 50%;
    margin-top: 0px;  
}
.premium-slider{
    width: 50%;
    margin-top: -20px; 
}

.button {
    margin: 0;
    width: 45px;
    height: 38px;
}

.button-slider {
    margin: 0;
    width: 45px;
}

.amount-one {
    width: 20%;
}

.amount-two {
    width: 31%;
    padding: 0px 5px 0px 5px;
}

.slider-component {
    width: 25%;
    padding: 0px 5px 0px 5px;
}

.tooltip-change {
    width: 20%;
    display: flex;
    flex-wrap: wrap;
    align-items: self-end;
    justify-content: center;
    position: relative;
}


.svg-toltip {
    margin-top: 35px;
    height: 50px; 
    width: 20px;
}
.svg-toltip-input{
    margin-top: -5px;
    height: 50px; 
    width: 20px;
}

.range-slider {
    position: relative;
    bottom: 5px;
    width: 100%;
    height: 1px;
    -webkit-appearance: none;
    background: #052675;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 23px;
    height: 25px;
    border: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18L0.607698 -1.9576e-06L21.3923 -1.40549e-07L11 18Z' fill='%23FDC740'/%3E%3C/svg%3E");
    cursor: pointer;
}

.range-slider::-moz-range-thumb {
    width: 23px;
    height: 24px;
    border: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18L0.607698 -1.9576e-06L21.3923 -1.40549e-07L11 18Z' fill='%23FDC740'/%3E%3C/svg%3E");
    cursor: pointer;
}

.output {
    position: relative;
    margin: 0;
    padding: 0;
    width: 35px;
    height: 35px;
    border: 1px solid #e2e2e2;
    background-color: #fff;
    border: 0px;
    pointer-events: none;
    background: transparent;
    text-align: justify;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 32px;
    color: #052675;
    align-self: start;
}

.min-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
}

.rider-titles {
    display: none;
}

@media (min-width:400px) {
    .amount-one {
        width: 20%;
    }

    .amount-two {
        width: 20%;
    }

    .slider-component {
        width: 40%;
    }

    .tooltip-change {
        width: 20%;
    }



}

@media (min-width:600px) {
    .amount-one {
        width: 10%;
    }

    .amount-two {
        width: 20%;
    }

    .slider-component {
        width: 60%;
    }

    .tooltip-change {
        width: 10%;
    }
    .output{
        font-size: 12px;
     }

}

@media (min-width: 900px) {
    .slider-row {
        height: 40px;
    }
    .title-premiun{
        display: none;
    }
    .value-premiun{
        display: flex;
    }
    .premium, .premium-slider{
        width: 5%;
    }
    .input-div {
        width: 75%;
        }
        .output{
            font-size: 14px;
         }

    .card-rider {
        background: #FFFFFF;
        min-width: 0;
        margin: 0 auto;
    }

    .range-slider {
        bottom: 15px;
    }

    .categories-label {
        top: 5px
    }

    .container-check {
        width: 50%;
        padding: 10px;
    }

    .container-field {
        width: 50%;
        padding: 0px;
    }

    .min-text {
        display: none;
    }

    .rider-titles {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-left: 31px;
        margin-top: 30px;
    }

    .amount-one {
        width: 20%;
        margin-top: 20px;
    }

    .amount-two {
        width: 20%;
        margin-top: 20px;
    }

    .slider-component {
        width: 35%;

    }

    .tooltip-change {
        width: 10%;
    }

    .svg-toltip {
        margin-top: 20px;
        height: 70px; 
        width: 30px;
    }
    .svg-toltip-input{
        margin-top: -5px;
        height: 70px; 
        width: 30px;
    }

    .amount-text {
        font-size: 18px;
    }
    .amount-text-slider {
        font-size: 16px;
        font-weight: bold;
    }

    .categories {
        font-size: 1.125rem;
        line-height: 32px;
        text-align: left;
        color: #4E4E4E;
    }
}

.tooltip-text {
    visibility: hidden;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    background: rgba(5, 38, 117, .9);
    color: #fff;
    text-align: center;
    width: 110px;
    z-index: 1;
    padding: 10px;
    border: 1px dashed #d3e9ff;
    box-sizing: border-box;
    border-radius: 10px;
    position: absolute;
    top: 60px  
}
.tooltip-text-slider {
    visibility: hidden;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    background: rgba(5, 38, 117, .9);
    color: #fff;
    text-align: center;
    width: 110px;
    z-index: 1;
    border: 1px dashed #d3e9ff;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px;
    position: absolute;   
}
.tooltip-change:hover .tooltip-text {
    visibility: visible;
}

.tooltip-input:hover .tooltip-text {
    visibility: visible;
}
.tooltip-change:hover .tooltip-text-slider {
    visibility: visible;
}

.tooltip-input:hover .tooltip-text-slider {
    visibility: visible;
}
.message-error {
    padding-left: 10px;
    margin-left: 1px;
}