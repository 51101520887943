.select-rider {
    width: 378px;
    height: 45px;
    left: 788px;
    top: 524px;
    background-color: #D3E9FF;
    opacity: 0.2;
    border-radius: 10px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    text-align: justify;
    color: #052675;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none; 
}

.option-rider{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    text-align: justify;
    color: #052675;
}

.icon-select{
    background: #052675;
    color: #D3E9FF;
    border-radius: 11px;
    width: 25px;
    pointer-events: none;
    margin-top: 10px;
}

.input::-ms-expand { 
    display: none;
}

.input {
    height: 45px;
    background-image: url("data:image/svg+xml, %3Csvg width='18' height='18' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12.5' cy='12.5' r='12.5' transform='rotate(-90 12.5 12.5)' fill='%23052675' /%3E%3Cpath d='M6.19807 10.2437L12.3769 15.8127L18.5557 10.2437' stroke='%23D3E9FF' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E");
     background-repeat: no-repeat;
    background-position: right center;
    appearance: none; 
    background-size: 20px;
    background-position: calc(100% - 15px) 12px;  
}
