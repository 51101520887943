.nav-logo {
    width: 277px;
    height: 31;
    position: absolute;
    left: 113px;
    top: 39px;
    background-repeat: no-repeat;
    background-size: cover;
}

.top {
    background: white;
    background-color: white;
    height: 132px;

}

.font-size-control {
    line-height: 1.8;
    color: #052675;
    font-size: 1rem;
    font-weight: 300;
}

.top-left-menu {
    font-size: 0.76rem;
    font-weight: bold;
}

@media (max-width: 767px) {
    .top-left-menu {
        justify-content: center !important;
        width: 100% !important;
        display: flex !important;
    }
}

@media (max-width: 562px) {
    .nav-logo {
        width: 250px;
        height: 34px;
        left: 10%;
    }

    .font-size-control {
        display: none;
    }

    .font-size-button {
        font-size: 0.875rem;
        padding: 3px 5px;
    }

}

@media (max-width: 340px) {
    .nav-logo {
        width: 210px;
        height: 30px;
        left: 10%;
    }
}

@media (max-width: 240px) {
    .nav-logo {
        width: 200px;
        height: 30px;
        left: 7%;
    }
}

.top-slim-menu {
    background: rgba(5.000000176951289, 38.0000015348196, 117.00000062584877, 1);
    min-height: 8px;
}

.spacer {
    background-color: rgba(238.00000101327896, 238.00000101327896, 238.00000101327896, 1);
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0px;
    top: 122px;
}