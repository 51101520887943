

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
}

.primary-background {
  background-color: #0088CE !important;
  color: #FFFFFF !important;
}

.secondary-background {
  background-color: #00257A !important;
  color: #FFFFFF !important;
}

.orange-background {
  background-color: #ffa14a !important;
  color: #FFFFFF !important;
}

.page {
  padding-top: 3em;
}

.page-form {
  padding-top: 2em;
}

.close-modal {
  color: #052675;
}

.modal-title {
  color: #052675;
}
@media (min-width: 1400px){
  .container {
      max-width: 1330px !important; 
  }
}
.form-group {
  width: 100%!important
}

.card-body .row > div {
  width: 100%;
}