.button-back {
    position: relative;
    float: right;
    width: 53px;
    height: 53px;
    background: #D3E9FF;
    box-shadow: inset 0px -3px 0px rgb(0 0 0 / 9%);
    border-radius: 5px;
    margin-right: 20px;
}

.button-next {
    position: relative;
    float: right;
    min-width: 254px;
}

.svg-back {
    position: absolute;
    top: 13px;
    right: 22px;
}

.border-content {
    min-height: 415px;
    background: #FFFFFF;
    border: 1px solid #D3E9FF;
    box-sizing: border-box;
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 30px;
}

.border-content-matrix {
    min-height: 415px;
    background: #FFFFFF;
    border: none;
}
.bottons-cards-container{
    padding-top: 1.5rem;
    padding-bottom: 5rem;
}

.bottons-cards {
    min-width: 280px;
    padding-bottom: 70px;
}

@media (max-width: 500px) {
    .bottons-cards {
        position: absolute;
        right: 12px;
    }
    .btn-text{
        font-size: 14px;
    }

    .button-next {
        min-width: 150px
    }

    .button-back {
        margin-right: 2px;
    }
}

@media screen and (max-width: 512px) {
    .row .bottons-cards-container {
        padding: 12px;
    }

    .bottons-cards {
        width: 92%;
        right: unset;
        left: unset;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        justify-items: center;
    }

    .bottons-cards .button-next, .button-back{
        float: unset;
    }

    .bottons-cards .button-next {
        min-width: 175px;
    }
}