.btn-assistant {
    position: fixed;
    right: 2rem;
    padding: 1rem;
    bottom: 5rem;
    color: #0088CE;
    cursor: pointer;
    background: #D3E9FF;
    border: 1px solid #F9FEFE;
    border-radius: 30px;
    box-sizing: border-box;
    width: 12.6rem;
    height: 8.3rem;
}

.chat-icon {
    position: fixed;
    right: 2em;
    bottom: 6em;
    color: #052675;
}

.btn-assistant-text {
    display: inline-flex;
    border: 2px solid #FFFFFF;
    border-radius: 36px;
    background: #D3E9FF;
    color: #052675;
    font-weight: bold;
    font-size: 16px !important;
    width: 128px;
    top: 50%;
    Height: 39px;
    position: absolute;
    right: 20px;
    width: 156px;
}

.help-position {
    font-size: 20px !important;
    position: absolute;
    left: 13%;
    top: 20%;
}

.chat-assistant {
    position: absolute;
    bottom: 156px;
    left: 65%;
    width:39px;
    height:37px;
}

.help-text {
    font-size: 15px !important;
    position: absolute;
    left: 32%;
    top: 16%;
}
.help {
    font-size: 15px !important;
    position: absolute;
    left: 35%;
    top: 16%;
    display: none;
}

.btn-assistant-border {
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 29.95%;
    right: 36.41%;
    bottom: 70.11%;
    height: 83px;
    width: 83px;
    background: white;
}

.btn-assistant-logo {
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 11.95%;
    right: 36.41%;
    bottom: 11.11%;
    height: 63px;
    width: 63px;
    background: linear-gradient(0deg, #2D9CDB, #2D9CDB);
}

.img-logo {
    top: 29%;
    position: absolute;
    left: 12px;
    width:40px;
    height:26px;
}

.line-assistant {
    margin-left: -7%;
    width: 193px;
}

@media (max-width: 600px) {
    .btn-assistant {
        right: 2rem;
        padding: 1rem;
        bottom: 5rem;
        width: 10.6rem;
        height: 7.3rem;
        background-color: transparent;
        border: none;
    }
    .help {
        display: flex;
    }
    .help-text{
        display: none;
    }
    .btn-assistant-text {
        width: 128px;
        top: 50%;
        right: 5%;
        Height: 39px;
        position: absolute;
    }

    .line-assistant {
        display: none;
        width: 160px;
    }
    .btn-assistant-border {
        height: 0px;
        width: 0px;
    }

    .btn-assistant-logo{
        position: absolute;
        height: 35px;
        width: 35px;
        top: 12px;
        left: 80px;
        z-index: 1;
    }
    .img-logo {
        left: 8px;
        width: 20px;
        height: 14px;
    }
    .chat-assistant {
        bottom: 70px;
        margin-left: 40px;
        width: 20px;
        height: 25px;
    }
    
    
}