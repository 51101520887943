textarea {
   overflow-x: hidden;
}

.input-txt {
   /* usually 30% of the div*/
   width: 40%;
}

/* when screen resolution is under 600px the date input will take 100% of the div*/
@media screen and (max-width: 600px) {
   .input-txt {
      width: 80% !important;
   }
}
.info-long-answer{
   margin-top: -45px;
   left: 300px;
   pointer-events: none;
   padding-right: 5px;
   position: absolute;
}

.long-i-matrix {
      right: 20px;
      pointer-events: none;
      padding-right: 5px;
      position: absolute;
}
