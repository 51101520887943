.form-group-contact {
    margin-bottom: 0px;
    padding-top: 0px;
    height: 55px;

}

.div-modal-contact {
    position: fixed;
    float: left;
    top: 0;
    left: 0;
    z-index: 1060;
    width: 100%;
    outline: 0;
    background: #49505787;
    height: 100%;
    display: flex;
    justify-content: center;

}

.modal-contact {
    background: #F8FBFF;
    border: 1px solid #D3E9FF;
    box-sizing: border-box;
    box-shadow: 0px -4px 14px rgba(211, 233, 255, 0.5);
    max-width: 301px;
    -webkit-border-top-left-radius: 15px;
    -webkit-border-top-right-radius: 15px;
    -moz-border-radius-topleft: 15px;
    -moz-border-radius-topright: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-sizing: border-box;
    max-height: 550px;
    top: 0%;
}

.modal-body-contact {
    padding-bottom: 0px;
}

.modal-content.modal-content__border {
    background: #F8FBFF;
    box-sizing: border-box;
    min-height: 580px;
    max-width: 301px;
    border-radius: 15px;
    box-sizing: border-box;
    border: none
}

.modal-contact__titles {
    margin: 15px 0px 0px 15px;
}

.modal-contact__title {
    /* Estoy indeciso, prefiero ser conectado directamente */

    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 19px;
    /* or 106% */
    text-align: center;
    color: #052675;
    margin-top: 5px;

}

.modal-contact__subtitle {

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #052675;
    margin-top: 5px;

}

.form-control::placeholder {

    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #052675;

}

#comments {
    height: auto;
}

.form-control.modal-contact__input {
    width: 266px;
    height: 33px;
    left: 18px;
    background: #FFFFFF;
    border: 1px solid #D3E9FF;
    box-sizing: border-box;
    border-radius: 5px;
    color: #052675;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;

}

.form-control.modal-contact__input.is-invalid-input {
    background-color: #F7F8F8;
    padding: 12px 12px;
    border: 1px dashed #F2274C;
}

.form-control.modal-contact__input:focus {
    background-color: white;
    box-shadow: none;
    border: 1px solid #d3e9ff;
}

.form-control.modal-contact__input.is-invalid-input:focus {
    background-color: #F7F8F8;
    padding: 12px 12px;
    border: 1px dashed #F2274C;
}

.btn-contact {
    width: 133px;
    position: absolute;
    top: 35px;
    float: right;
    left: 160px;
}

.svg-separator {
    position: absolute;
    top: 50px;
    left: 5px;
}

.btn-text-contact {
    border: 0px solid #FDC740;
    height: 31.654844284057617px;
    background: #FDC740;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.086);
    border-radius: 5px 0px 0px 5px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 110.2%;
    text-align: center;
    text-transform: uppercase;
    color: #052675;
}

.btn-image-contact {
    visibility: hidden;
}

.modal-contact__action {
    position: relative;
}

.btn-image-action {
    background: #FFDB81;
    position: absolute;
    left: 75px;
    bottom: 27px;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.086);
    border-radius: 0px 5px 5px 0px;
    width: 31.65px;
    height: 31.654844284057617px;

}

.position-sticky-contact {
    position: absolute;
    top: 80px;
    left: 10px;
}

@media (max-width: 500px) {
    .modal-contact{
    top: 0%
    }
        
}
.form-control.modal-contact__input.custom-select-matrix{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxjaXJjbGUgY3g9IjEyLjUiIGN5PSIxMi41IiByPSIxMi41IiB0cmFuc2Zvcm09InJvdGF0ZSgtOTAgMTIuNSAxMi41KSIgZmlsbD0iIzA1MjY3NSIvPg0KPHBhdGggZD0iTTYuMTk4MDcgMTAuMjQzN0wxMi4zNzY5IDE1LjgxMjdMMTguNTU1NyAxMC4yNDM3IiBzdHJva2U9IiNEM0U5RkYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg==");
    background-repeat: no-repeat;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background-position-x: 95%;
    background-position-y: 8px;  
    background-size: 17px 17px;
}


.div-thumbs {
    position: absolute;
    background: #fff no-repeat center;
    margin-top: -45px;
    margin-left: 100px;
    padding-left: 10px;
    color: #1188c9;
}

.div-title-message {
    color: #033b85;
    width: 90%;
    text-align: center;
}

.message-content {
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
}

.main-box-message {
    margin: auto;
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: #edda1d;
    width: 90%;
    height: 90%;
    position: relative
}
