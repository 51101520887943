.container-btn,
.btn-image {
    float: left;
}

.container-btn:hover,
.btn-image:hover {
    background: #FDC740;
}

.btn-image:hover {
    background: #FFDB81;
}

.btn {
    text-transform: uppercase;
    width: 100%;
    padding: 0px;
  }

.btn-text {
    border: 0px solid #FDC740;
    height: 53px;
    background: #FDC740;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.086);
    border-radius: 5px 0px 0px 5px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 110.2%;
    text-align: center;
    text-transform: uppercase;
    color: #052675;
}

.btn-text-product {
    border: 0px solid #D3E9FF;
    height: 53px;
    background: #D3E9FF;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.086);
    border-radius: 5px 0px 0px 5px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 110.2%;
    text-align: center;
    text-transform: uppercase;
    color: #052675;
}


.btn-image {
    width: 53px;
    height: 55.2px;
    position: absolute;
    background: #FFDB81;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.086);
    border-radius: 0px 5px 5px 0px;
    top: -1.5px
}

.btn-image-white {
    width: 13px;
    position: absolute;
    background: white;
    top: 12px;
    right: 20px;
}
.btn-image-product {
    width: 53px;
    height: 56px;
    position: absolute;
    background: #c9e2fa;
    box-shadow: inset 0px -3px 0px rgb(0 0 0 / 9%);
    border-radius: 0px 5px 5px 0px;
    top: -1.5px;
}


.image-svg {
    fill: #052675;
    float: right;
    margin-top: 12px;
    margin-right: 12px;
}

.image-svg-landing {
    fill: #052675;
    float: right;
}

.card-footer {
    background-color: rgba(0, 0, 0, 0);
    border-top: 0px rgba(0, 0, 0, 0);
}


.col-left {
    left: -12px;

}

span {
    padding-right: .5em;
}

.btn-a {
    text-align: center;
    line-height: 35px;
}

.button.block {
    display: block;
    width: 200px;
}

.margin-auto {
    margin: 0 auto;
}

.plus {
    background-color: rgba(5.000000176951289, 38.0000015348196, 117.00000062584877, 1);
    width: 18.515625px;
    height: 18.515625px;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.open>.dropdown-toggle.btn-primary {
    outline: none !important;
    box-shadow: none;
    color: #052675;
}
.btn-white {
height: 53px;
border: 1px solid #052675;
box-sizing: border-box;
border-radius: 5px;
background-color: white !important;
color:#052675;
font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 110.2%;
text-align: center;
text-transform: uppercase;
margin-left: 25px;
}
.text-btn-white{
    padding: 0px 40px 0px 10px;
}

/* when screen resolution is under 600px*/
@media screen and (max-width: 300px) {
    .text-button {
        font-size: 13px;
    }
}
@media screen and (max-width: 600px) {
    .button-next-rider{
        min-width: 300px;
    }
}
.btn {
    padding: 0px;

}
.text-capitalize {
    display: inline-block;
    width: 100%;

}